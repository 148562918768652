import React, { PureComponent } from 'react'
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import { Row, Select, Form, Spin, Button, Table, Tooltip as Tooltip2, Popconfirm, notification, Modal, DatePicker, Radio, InputNumber } from 'antd';
import { useState, useEffect } from 'react';
import { API_getcounters, API_getrecords, API_deleterecord, API_addcounterrecord } from '../api/counters';
import PageTitle from '../components/PageTitle';
import { PlusOutlined, EditOutlined, DeleteOutlined, SaveOutlined, ZoomInOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { API_getdata } from '../api/report';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';

const CounterSingle = (props) => {

    var moment = require('moment');

    const [recoddel, setrecoddel] = useState(true);
    const [update, setupdate] = useState(true);
    const [modalVisible, setmodalVisible] = useState(false);

    const [counterSerialNum, setcounterSerialNum] = useState(undefined);
    const [counterModel, setcounterModel] = useState(undefined);
    const [nameObjects, setnameObjects] = useState(undefined);

    const [modalZoom, setmodalZoom] = useState(false);

    const [reportsrow, setReportsRow] = useState([]);
    const [idReportRow, setidReportRow] = useState([]);

    const [radio, setradio] = useState("dd.MM.yyyy");

    const [maxY, setmaxY] = useState(1);

    const [heatReport, setheatReport] = useState([]);
    const [heatReport2, setheatReport2] = useState([]);

    const [grafik, setgrafik] = useState([]);

    const startDateOfTheYear = moment().startOf('month').format('YYYY-MM-DD');

    const  endDateOfTheYear = moment().endOf('month').format('YYYY-MM-DD');


    const [startDate, setstartDate] = useState(startDateOfTheYear);
    const [endDate, setendDate] = useState(endDateOfTheYear);

    const [counters, setcounters] = useState([]);

    const [records, setrecords] = useState([]);

    const [recordsr, setrecordsr] = useState([]);

    const [loading, setLoading] = useState(false);

    const [loading2, setLoading2] = useState(false);

    const [loading3, setLoading3] = useState(false);

    const [loading4, setLoading4] = useState(false);

    const [type, setType] = useState('');
    function modalVis() {
            setmodalVisible(!modalVisible)
    }

    function zoom() {
        setmodalZoom(!modalZoom)
    }

    const resetZoom = () => {
        setmodalZoom(!modalZoom)
    };

    const exportPdf = () => {

        const input = document.getElementById('divToOfferInfo');
            const pdf = new jsPDF();
            if (pdf) {
              domtoimage.toPng(input)
                .then(imgData => {
                  pdf.addImage(imgData, 'PNG', 10, 10);
                  pdf.save('report.pdf');
                });
            }
    }

    const columns = [
        {
            title: 'Показание прибора учета',
            key: 'record',
            dataIndex: 'record',
            width: '40%',
        },
        {
            title: 'Ед.изм.',
            key: 'counterTypeMeter',
            dataIndex: 'counterTypeMeter',
            width: '5%',
        },
        {
            title: 'Тип',
            key: 'priceTypeSt',
            dataIndex: 'priceTypeSt',
            width: '5%',
        },
        {
            title: 'Месяц',
            key: 'period',
            dataIndex: 'period',
            width: '15%',

        },
        {
            title: 'Кто добавил',
            key: 'userLogin',
            dataIndex: 'userLogin',
            width: '15%',
        },
        {
            title: 'Дата ввода',
            width: '15%',
            render: row => {
                const datetime = moment(row.dateInsert)
                    .utc()
                    .format('DD.MM.YYYY HH:mm:ss');
                return (
                    <>
                        <p>{datetime}</p>
                    </>
                );
            },
        },
        {
            title: 'Действия',
            render: row => {
            if (row.recordStatus!=1) {
                setrecoddel(false)
            }
            return (
                <>
                {recoddel?
                <Tooltip2 title="Удалить показание">
                    <Popconfirm title="Вы действительно хотите удалить？" placement="bottom" onConfirm={() => confirm(row.id)} okText="Да" cancelText="Нет">
                        <a><DeleteOutlined style={{ color: 'red' }}/></a>
                    </Popconfirm>
                </Tooltip2>
                :null
                }
                </>
            )
        },
        },
    ];

    const [form] = Form.useForm();

    const convert = toConvert => {
        const map = toConvert.reduce((r, {dt5, dt4, dt7}) => {
          if (r.has(dt5))
            r.set(dt5, {...r.get(dt5), [dt4]: dt7});
         else
                r.set(dt5, {dt5, [dt4]: dt7});
          return r;
      }, new Map());
      return [...map.values()];
    };


    useEffect(() => {

        setLoading(true);

            API_getdata({ type: "grafik", id1: props.match.params.id , id2: idReportRow, id3: startDate, id4: endDate, id5: radio  }).then(data =>

                {
                  if (data!=undefined) {

                    var mxY = 1

                    data.map(dt => {
                        if (Number(Math.round(dt.dt7))>mxY) {

                            mxY = Number(Math.round(dt.dt7))

                        }
                    }

                    )

                    setmaxY(mxY)

                    setgrafik(convert(data))

                  }

                }

                )
                .finally(() => setLoading(false));

    }, [idReportRow,startDate,endDate, radio]);

    useEffect(() => {
        setLoading2(true)
        API_getdata({ type: "grafikRow", id1: props.match.params.id })
            .then(data => {
                setReportsRow(data)
                setidReportRow(data[0].dt1)
            })
            .finally(() => setLoading2(false));

    }, []);

    useEffect(() => {
        setLoading3(true)
        //console.log('startDate',props.match.params.id, type)
        API_getdata({ type: type, id1: props.match.params.id, id2: startDate, id3: endDate }).then(data => setheatReport(data)).finally(() => setLoading3(false));
    }, [idReportRow,startDate,endDate]);

    useEffect(() => {
        setLoading4(true)
        API_getdata({ type: type+"2", id1: props.match.params.id, id2: startDate, id3: endDate }).then(data =>
            {
                setheatReport2(data)
            }



            )
            .finally(() => setLoading4(false));
    }, [idReportRow,startDate,endDate]);

    function confirm(recordid) {
        setLoading(true);
                const json = {
                    recordId: recordid,
                };
                API_deleterecord(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Показание удалено' });
                            props.history.push(`/viewcounter/${props.match.params.id}`);
                            setupdate(!update)
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
      }



        useEffect(() => {
            setLoading(true)
            API_getcounters({ id: props.match.params.id, name: "", IdObject: ""})
            .then(data => {
                setcounters(data)
                localStorage.setItem('lastRecord', data[0].lastRecord);
                setnameCounter(data[0].counterName)
                setcounterSerialNum(data[0].counterSerialNum)
                setcounterModel(data[0].counterModel)
                setnameObjects(data[0].nameObjects)
                    switch(data[0].counterName){
                        case 'ГВС': //Выбор даты ВИ
                            setType('heatwaterreport')

                            break;
                        case 'ХВС': //Участвует в конкурсе
                            setType('coldwaterreport')
                            break;
                        default:
                            setType('heatreport');
                    }
            }
            )
            .finally(setLoading(false));

        }, [update]);


        useEffect(() => {
            setLoading(true)
            API_getrecords({ id: props.match.params.id})
            .then(data =>
                {
                setrecords(data)
                setrecordsr(data.reverse())
                }
                )
            .finally(setLoading(false));
        }, [update]);

        const [nameCounter, setnameCounter] = useState(undefined);

        const routes = [
        { label: 'Главная', path: '/' },
        { label: 'Приборы учета', path: `/counters` },
        { label: nameCounter, path: `/viewcounter/${props.match.params.id}` },
        ]

        const [godMode, setgodMode] = useState(false);

        useEffect(() => {
            if (localStorage.getItem('userType') == 1) {
                setgodMode(true)
            }
        }, []);

        const resetModal = () => {
            setmodalVisible(!modalVisible)
            form.resetFields();
        };

        const [DateRecord, setDateRecord] = useState(undefined);

        const [minRecord1, setminRecord1] = useState(undefined);
        const [minRecord2, setminRecord2] = useState(undefined);

        const [minRecord, setminRecord] = useState(undefined);

        useEffect(() => {
            setLoading(true)
            API_getcounters({ id: props.match.params.id, name: "", IdObject: ""})
            .then(response => {
                setminRecord1(response[0].lastRecord1)
                setminRecord2(response[0].lastRecord2)
                if (response[0].recordType==2) {
                    setminRecord1(0)
                    setminRecord2(0)
                }
            }
                )
            .finally(setLoading(false));
        }, [update]);

        const onChangeRadio = e => {

            if (e.target.value==2) {
                setminRecord(minRecord2)
            }

            if (e.target.value==1) {
                setminRecord(minRecord1)
            }

        }

        const handleSubmit = values => {
                    setLoading(true);
                    const json = {
                        record: Number(values.record),
                        dateRecord: DateRecord,
                        counterId: props.match.params.id,
                        priceType: Number(values.priceType),
                    };
                        API_addcounterrecord(json)
                        .then(function(response) {
                                if (response.id==1) {
                                    notification.success({ message: 'Данные сохранены' });
                                }
                                resetModal()
                                setupdate(!update)
                        })
                        .catch(function(error) {
                            try {
                                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                            } catch {
                                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                            }
                            throw Error;
                        })
                        .finally(() => setLoading(false))
                    ;
        };

        function onChangeDateRecord(dateString) {
            if (dateString!=null) {
                setDateRecord(dateString.format('YYYYMM'));
            }
        }

    const dateFormat = 'YYYYMM';

    class CustomizedLabel extends PureComponent {
        render() {
            const {
                x, y, stroke, value,
            } = this.props;

            return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle" >{value}</text>;
        }
    }
    const {  RangePicker } = DatePicker;

    const colors = [
        '#00FF00',
        '#0033cc',
        '#996600',
        '#006600',
        '#007399'
    ];

    const getRandomColor = () => {
        return colors[Math.floor(Math.random() * colors.length)];
    };

    function onDtChange(date,dateString) {

            setstartDate(dateString[0]);

            setendDate(dateString[1]);

      }

    const onChange = e => {

        setradio(e.target.value);

      };

    return (
        <>
            <Modal
                    okButtonProps={{ style: { display: 'none' } }}
                    title={`Добавление показателя прибора учета`}
                    onCancel={resetModal}
                    visible={modalVisible}
                    centered
                    width={900}
                    >
                <Form form={form} onFinish={handleSubmit} layout='vertical'>
                    <Spin spinning={loading}>
                        <Form.Item label="Показатель прибора учета" style={{ marginBottom: 0 }}
                        name="record" rules={[{required: true, message: 'Введите показатель'}]}>
                                <InputNumber  style={{ width: "100%" }} autoComplete="off" placeholder="введите показатель" min={minRecord}/>
                        </Form.Item>
                        <Form.Item label="Расчетный месяц (формат ГГГГММ)" style={{ marginBottom: 0 }} name="dateRecord" rules={[{required: true, message: 'Введите месяц'}]}>
                                        <DatePicker picker="month" autoComplete="off" format={dateFormat} onChange={onChangeDateRecord}/>
                        </Form.Item>
                        <Form.Item label="Тип показателя" style={{ marginBottom: 0 }}
                            name="priceType" rules={[{required: true, message: 'выберите тип'}]}>
                                <Radio.Group onChange={onChangeRadio} >
                                    <Radio value={1}>Обычный</Radio>
                                    <Radio value={2}>Ночной</Radio>
                                </Radio.Group>
                        </Form.Item>
                        <Button icon={<SaveOutlined />} style={{marginTop : 20}} htmlType="submit" type="primary">
                            Сохранить
                        </Button>
                    </Spin>
                </Form>

            </Modal>
            <Modal
                    okButtonProps={{ style: { display: 'none' } }}
                    title={`График IoT`}
                    onCancel={resetZoom}
                    visible={modalZoom}
                    centered
                    width="100%"
                    >
                <div>
                    <ResponsiveContainer width="100%" height={400}>

                        <LineChart
                            data={grafik}
                            margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                            >
                                <CartesianGrid strokeDasharray="8 8" />
                                <XAxis dataKey="dt5" padding={{ left: 30, right: 30 }} />
                                <YAxis type="number" interval={1} domain={[0, Number(maxY)]} padding={{ top: 30}}/>
                                <Tooltip />
                                <Legend />
                                {
                                reportsrow.map(repdata => {
                                    return (idReportRow.includes(repdata.dt1)) ?
                                    <Line key={repdata.dt3} type="monotone" dataKey={repdata.dt3} stroke={getRandomColor()} />
                                    : null })}

                        </LineChart>

                    </ResponsiveContainer>
                </div>
            </Modal>
            <PageBreadcrumbs routes={routes} />

            <Form  className="che-form">
                <Spin spinning={loading}>
                { godMode ?
                <div align="right">
                    <Tooltip2 title="Редактировать прибор учета">
                        <Button icon={<EditOutlined />} onClick={() => props.history.push(`/editcounter/${props.match.params.id}`)} type="primary">
                        </Button>
                    </Tooltip2>
                </div>
                : null}
                <PageTitle title="Прибор учета" />
                <Form.Item>
                {counters && counters.map(counters =>
                    (
                            <div>

                                <p>Наименование прибора: {counters.counterName}</p>

                                <p>Тип прибора: {counters.counterTypeName}</p>

                                <p>Объект: {counters.nameObjects}</p>

                                <p style={{marginBottom : 10}}>Тариф: {counters.tarifName}</p>

                                <details>

                                        <p>Начальные показания прибора учета (Обычный): {counters.startRecord1}</p>

                                        <p>Начальные показания прибора учета (Ночной): {counters.startRecord2}</p>

                                        <p>Коэфициент: {counters.counterKoef}</p>

                                        <p>Серийный номер: {counters.counterSerialNum}</p>

                                        <p>Модель: {counters.counterModel}</p>

                                        <p>Марка: {counters.counterMark}</p>

                                        {moment(counters.dateCounter).format('DD.MM.YYYY')=='01.01.1900' ? <p>Дата выпуска: Не указан</p> : <p>Дата выпуска: {moment(counters.dateCounter).format('DD.MM.YYYY')}</p>}

                                        {moment(counters.dateSet).format('DD.MM.YYYY')=='01.01.1900' ? <p>Дата установки: Не указан</p> : <p>Дата установки: {moment(counters.dateSet).format('DD.MM.YYYY')}</p>}

                                        <p>Дата поверки: {moment(counters.dateVerification).utc().format('DD.MM.YYYY')}</p>

                                        <p>Дата поверки плановая: {moment(counters.dateVerificationPlane).utc().format('DD.MM.YYYY')}</p>

                                        <p>Статус: {counters.counterStatus}</p>

                                        <p>Тип ввода данных: {counters.recordType==1 ? "Показание счетчиков" : "Разница показаний"}</p>

                                </details>
                             </div>
                        )
                        )}
                </Form.Item>
                <div align="right">
                    <Button icon={<PlusOutlined />} style={{marginBottom : 20}} onClick={() => modalVis()} type="primary">
                            Добавить показания
                    </Button>
                </div>
                <PageTitle title="Показания" />
                    <Table
                        columns={columns}
                        dataSource={records}
                        rowClassName="row-hover"
                        pagination={{pageSize: 12}}
                        size="small"
                    />
                <PageTitle title="График" />
                <Form.Item>
                <div id="node-to-convert">
                    <ResponsiveContainer width="100%" height={400}>

                        <LineChart
                            data={recordsr}
                            margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                            >
                                <CartesianGrid strokeDasharray="8 8" />
                                <XAxis dataKey="period" padding={{ left: 30, right: 30 }} />
                                <YAxis padding={{ top: 30}}/>
                                <Tooltip />
                                <Legend />
                                <Line key="record" type="monotone" dataKey="record" stroke={getRandomColor()} label={<CustomizedLabel />}/>
                        </LineChart>

                    </ResponsiveContainer>
                </div>
                </Form.Item>
                {(typeof reportsrow !== 'undefined' && reportsrow.length > 0) &&
                <div>

                    <PageTitle title="График IoT" />
                    <Row>
                        <Form.Item label="Период" style={{ marginRight : 10 }}>
                            <RangePicker onChange={onDtChange} defaultValue={[moment(startDateOfTheYear,'YYYY-MM-DD'), moment(endDateOfTheYear,'YYYY-MM-DD')]} />
                        </Form.Item>
                        <Form.Item label="Вид" style={{ marginRight : 10 }}>
                            <Radio.Group size="small" onChange={onChange} value={radio}>
                                <Radio value={"dd.MM.yyyy HH"}>Часы</Radio>
                                <Radio value={"dd.MM.yyyy"}>Дни</Radio>
                                <Radio value={"MM.yyyy"}>Месяц</Radio>
                                <Radio value={"yyyy"}>Год</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Row>
                    <Form.Item label="Тип" >
                                <Select
                                    style={{ width: '100%' }}
                                    value={idReportRow}
                                    onChange={setidReportRow}
                                    placeholder="выберите"
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    mode="multiple"
                                >
                                    {reportsrow.map(reprow => (
                                        <Select.Option key={(reprow.dt1)} value={(reprow.dt1)}>
                                        {reprow.dt3}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                    <Form.Item>
                    <div>
                        <ResponsiveContainer width="100%" height={400}>

                            <LineChart
                                data={grafik}
                                margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                                >
                                    <CartesianGrid strokeDasharray="8 8" />
                                    <XAxis dataKey="dt5" padding={{ left: 30, right: 30 }} />
                                    <YAxis type="number" interval={1} domain={[0, Number(maxY)]} padding={{ top: 30}}/>
                                    <Tooltip />
                                    <Legend />
                                    {
                                    reportsrow.map(repdata => {
                                        return (idReportRow.includes(repdata.dt1)) ?
                                        <Line key={repdata.dt3} type="monotone" dataKey={repdata.dt3} stroke={getRandomColor()} />
                                        : null })}

                            </LineChart>

                        </ResponsiveContainer>
                    </div>
                    <Form.Item>
                        <div align="right">
                            <Button icon={<ZoomInOutlined />} onClick={() => zoom()} type="primary">
                                Увеличить график
                            </Button>
                        </div>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item>
                    <PageTitle title="Отчет" />
                    <div id="divToOfferInfo" style={{marginLeft : 10}}>
                        <Spin spinning={loading3}>
                            <p>Отчет о суточных параметрах теплоснабжения с {startDate} по {endDate}</p>
                            <p>Потребитель: {nameObjects}</p>
                            <p>Характеристики средств измерения: M1min=т/сутки M1max=т/сутки M2min=т/сутки M2max=т/сутки</p>
                            <p style={{marginBottom : 10}} >Прибор учета: {counterModel} №{counterSerialNum} Тхв = 0,0 C</p>

                            <div style={{marginBottom : 15 , width : 600}}>
                                <table border="1" class="table">
                                    {type == 'coldwaterreport' ?
                                        <tr><th>Дата</th><th>Объем (м3)</th><th>Время наработки (ч)</th></tr>
                                    :
                                        type == 'heatwaterreport' ?
                                            <tr><th>Дата</th>    <th>t1 C</th>     <th>t2 C</th>   <th>dt C</th>     <th>V1 m3</th>     <th>V2 m3</th> <th>dV</th>       <th>Q Гкал</th>    <th>BHP ч</th></tr>

                                        :
                                            <tr><th>Дата</th><th>t1 C</th><th>t2 C</th><th>dt C</th><th>M1 t</th><th>M2 t</th><th>dM т</th><th>Q Гкал</th><th>BHP ч</th></tr>}

                                    {heatReport.map(hr => (
                                    type == 'coldwaterreport' ?
                                        <tr><td>{hr.dt1}</td><td>{hr.dt3}</td><td>{hr.dt4}</td></tr>
                                    :
                                        type == 'heatwaterreport' ?
                                            <tr><td>{hr.dt1}</td><td>{hr.dt2}</td> <td></td>       <td>{hr.dt2}</td> <td>{hr.dt3}</td>  <td></td>      <td>{hr.dt4}</td> <td>{hr.dt5}</td>   <td>{hr.dt6}</td></tr>
                                        :
                                            <tr><td>{hr.dt1}</td><td>{hr.dt2}</td><td>{hr.dt3}</td><td>{hr.dt4}</td><td>{hr.dt5}</td><td>{hr.dt6}</td><td>{hr.dt7}</td><td>{hr.dt8}</td><td>{hr.dt9}</td></tr>
                                    ))}
                                </table>
                            </div>
                        </Spin>
                        <p style={{marginTop : 20, marginBottom : 5}} >Показания счетчиков</p>
                        <Spin spinning={loading4}>
                        <div style={{ marginBottom : 10 , width : 400}}>
                            <table border="1" class="table">
                                {type == 'coldwaterreport' ?
                                    <tr><th>Дата</th><th>Объем (м3)</th><th>Время наработки (ч)</th></tr>
                                    :
                                    type == 'heatwaterreport' ?
                                        <tr><th>Дата</th><th>V1 m3</th><th>V2 m3</th><th>Q Гкал</th><th>BHP ч</th></tr>
                                        :
                                        <tr><th>Дата</th><th>М1 т</th><th>М2 т</th><th>Q Гкал</th></tr>}


                                {heatReport2.map(hr => (
                                    type == 'coldwaterreport' ?
                                        <tr><td>{hr.dt1}</td><td>{hr.dt3}</td><td>{hr.dt4}</td></tr>
                                        :
                                        type == 'heatwaterreport' ?
                                            <tr><td>{hr.dt1}</td><td>{hr.dt3}</td><td></td><td>{hr.dt2}</td><td>{hr.dt4}</td></tr>
                                            :
                                    <tr><td>{hr.dt1}</td><td>{hr.dt2}</td><td>{hr.dt3}</td><td>{hr.dt4}</td></tr>
                                ))}
                            </table>
                        </div>
                        </Spin>
                        <p style={{marginBottom : 10}}>Ответственный за учет теплоэнергии:</p>
                        <p style={{marginBottom : 10}} >Представитель снабжающей организации:</p>
                    </div>
                        <Button icon={<SaveOutlined />} onClick={() => exportPdf()} type="primary">
                            Сохранить отчет
                        </Button>
                    </Form.Item>

                </div>
                }
                </Spin>
            </Form>
        </>
    )
}

export default CounterSingle;