import React from 'react'
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import { Form, Spin, Button, Tooltip, Table, Modal, Radio, DatePicker, notification, Input, Popconfirm } from 'antd';
import { useState, useEffect } from 'react';
import {  API_gettafifs, API_getprice, API_addprice, API_deleteprice } from '../api/tarif';
import PageTitle from '../components/PageTitle';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const TarifSingle = (props) => {

    var moment = require('moment');

    const [tarifs, settarifs] = useState([]);

    const [loading, setLoading] = useState(false);

    const [godMode, setgodMode] = useState(false);

    const [update, setupdate] = useState(false);

    const [price, setprice] = useState([]);

    const [period, setperiod] = useState(undefined);

    const [modalVisible, setmodalVisible] = useState(false);

    const { RangePicker } = DatePicker;

    function modalVis() {
            setmodalVisible(!modalVisible)
        }

    const columns = [
        {
            title: 'Период',
            key: 'periodSt',
            dataIndex: 'periodSt',
            width: '30%',
        },
        {
            title: 'Тариф руб.',
            key: 'price',
            dataIndex: 'price',
            width: '30%',
        },
        {
            title: 'Тип',
            width: '30%', 
            key: 'priceTypeSt',
            dataIndex: 'priceTypeSt',

        },
        {
            title: 'Действия',
            render: row => {
            return (    
                <>
                {
                <Tooltip title="Удалить тариф">
                    <Popconfirm title="Вы действительно хотите удалить？" placement="bottom" onConfirm={() => confirm(row.idPrice)} okText="Да" cancelText="Нет">
                        <a><DeleteOutlined style={{ color: 'red' }}/></a>   
                    </Popconfirm>
                </Tooltip>
                }
                </>
            )
        },
        },
    ];

    function confirm(idPrice) {
        setLoading(true);
                const json = {
                    idPrice: idPrice,
                };
                API_deleteprice(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Удалено' });
                            setupdate(!update)
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    }

    useEffect(() => {
        setLoading(true)

        API_getprice({ id: props.match.params.id})
        .then(data => {
            setprice(data)
        })

        API_gettafifs({ id: props.match.params.id, name: ""})
        .then(data => {
            settarifs(data)
            setnameTarif(data[0].tarifName)
        })
        .finally(setLoading(false));

    }, [update]);

    useEffect(() => {
        if (localStorage.getItem('userType') == 1) {
            setgodMode(true)
        } 
    }, []);

    const [nameTarif, setnameTarif] = useState(undefined);

    const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Тарифы', path: `/tarifs` },
    { label: nameTarif, path: `/viewtarif/${props.match.params.id}` },
    ]

    const [form] = Form.useForm();

    const resetModal = () => {
        setmodalVisible(!modalVisible)
        form.resetFields();
    };

    const handleSubmit = values => {
        setLoading(true);
        const json = {
            period: period,
            price: Number(values.price),
            idTarif: Number(props.match.params.id),
            priceType: Number(values.priceType),
        }
        API_addprice(json)
            .then(function(response) {
                notification.success({ message: 'Данные сохранены' });
                modalVis()
                setupdate(!update)
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
            .finally(() => setLoading(false));

    }

    const monthFormat = 'YYYYMM';

    function onChangeplanPeriod(date,dateString) {
        setperiod(dateString[0]+'-'+dateString[1])
    }


    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Spin spinning={loading}>
            <Form className="che-form">
            <PageTitle title="Тариф" />
                { godMode ?
                <div align="right">
                    <Tooltip title="Редактировать тариф">
                        <Button icon={<EditOutlined />} onClick={() => props.history.push(`/edittarif/${props.match.params.id}`)} type="primary"> 
                        </Button>
                    </Tooltip>
                </div>
                : null}
                {tarifs &&
                tarifs.map(tarifs => (
                            <div>
                                <p>Название: {tarifs.tarifName}</p>

                                <p>Тип: {tarifs.tarifInfo}</p>

                             </div>
                        ))}
                
            <div align="right">
                <Button icon={<EditOutlined />} onClick={() => modalVis()} type="primary"> 
                    Добавить цену
                </Button>
            </div>    
            <PageTitle title="Цены на тариф" />        
                <Table
                    size="small"
                    columns={columns}
                    dataSource={price}
                    rowClassName="row-hover"
                    pagination={false}
                />
                <Modal
                    okButtonProps={{ style: { display: 'none' } }}
                    title={`Добавить цену тарифа`}
                    onCancel={resetModal}
                    visible={modalVisible}
                    centered
                    width={900}
                    >
                    <Form form={form} wrapperCol={{ span: 20 }} labelCol={{ span: 6 }} style={{ width: 600, margin: '0 auto' }} onFinish={handleSubmit} > 
                        <Form.Item  label="Период" style={{ marginBottom: 0 }} name="period" rules={[{required: true, message: 'Введите период'}]}>
                            <RangePicker style={{marginBottom : '20px'}} format={monthFormat} autoComplete="off" onChange={onChangeplanPeriod} picker="month"/>
                        </Form.Item>
                        <Form.Item label="Тариф руб. без НДС" style={{ marginBottom: 0 }} 
                        name="price" rules={[{required: true, message: 'введите тариф'}]}>
                                <Input autoComplete="off" type="number" placeholder="введите тариф" maxLength="250"/>
                        </Form.Item>
                        <Form.Item label="Тип тарифа" style={{ marginBottom: 0 }} 
                        name="priceType" rules={[{required: true, message: 'выберите тип'}]}>
                            <Radio.Group>
                                <Radio value={1}>Обычный</Radio>
                                <Radio value={2}>Ночной</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 6, span: 18 }} style={{ marginBottom: 20 }}>
                            <Button type="primary" htmlType="submit">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>    
            </Form>
            </Spin>
        </>
    )
}

export default TarifSingle;