import React from 'react';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { Table, Spin, Form, Input, Button} from 'antd';
import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_gettafifs } from '../api/tarif';
import { PlusOutlined } from '@ant-design/icons';

var moment = require('moment');

const routes = [{ label: 'Главная', path: '/' }, 
                { label: 'Тарифы', path: '/tarifs' }];

const columns = [
    {
        title: 'Название',
        key: 'tarifName',
        dataIndex: 'tarifName',
    },
    {
        title: 'Тип',
        key: 'tarifInfo',
        dataIndex: 'tarifInfo',
    },
    {
        title: 'Дата добавления',
        key: 'startDate',
        render: row => {
            const datetime = moment(row.startDate)
                .utc()
                .format('DD.MM.YYYY');
            return (
                <>
                    <p>{datetime}</p>
                </>
            );
        },
    },
];

const Tarifs = props => {

    const [loading, setLoading] = useState(false);
    const [tarifs, settarifs] = useState([]);

    const [tarifName, settarifName] = useState("");


    useEffect(() => {
        setLoading(true)
        API_gettafifs({ id: "", name: tarifName})
        .then(data => settarifs(data))
        .finally(setLoading(false));

    }, [tarifName]);

    const [godMode, setgodMode] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('userType') == 1) {
            setgodMode(true)
        } 
    }, []);
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            
            <Form className="che-form">
                <PageTitle title="Тарифы" />
                <Form layout="vertycal">
                { godMode ?
                <div align="right">
                    <Button icon={<PlusOutlined />} style={{marginBottom : 20}} onClick={() => props.history.push('/addtarif')} type="primary">
                        Добавить тариф
                    </Button>
                </div>
                : null}
                <Form.Item label="Название">
                    <Input.Search onSearch={settarifName} placeholder="поиск по названию" />
                </Form.Item>
                </Form>
                <Spin spinning={loading}>
                    <Table
                        size="small"
                        columns={columns}
                        dataSource={tarifs}
                        rowClassName="row-hover"
                        pagination={{
                            pageSize: 100,
                            position: 'both',
                        }}
                        onRow={tarifs => {
                            return {
                                onClick: () => props.history.push(`/viewtarif/${tarifs.id}`),
                            };
                        }}
                    />
                </Spin>
            </Form>
        </>
    );
};

export default withRouter(Tarifs);
