import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import Login from './layouts/Login'
import Main from './layouts/Main'
import Fail from './layouts/Fail'
import { ProtectedRoute } from './components/ProtectedRoute'
import { LoginRoute } from './components/LoginRoute';
import { connect } from 'react-redux';
import { setUser } from './redux/actions'
import { ConfigProvider } from 'antd';
import ruRu from 'antd/es/locale/ru_RU';

const App = (props) => {

    useEffect(() => {
        if(localStorage.getItem('accessToken') !== null) {
            props.setUser(localStorage.getItem('login'))
        }
    }, [])

    return (
        <ConfigProvider locale={ruRu}>
            <Router>
                <Switch>
                    {/*<Route path="/" component={Fail}/>*/}

                    {<LoginRoute path="/login" component={Login} />}
                    {<ProtectedRoute path="/" component={Main} />}
                </Switch>
            </Router>
        </ConfigProvider>
    )
}
const mapStateToProps = state => {
    return {
        login: state.user.login,
    }
}

export default connect(mapStateToProps, { setUser })(App)
