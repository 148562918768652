import React from 'react';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { Table, Spin, Form, Input, Button} from 'antd';
import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_getcontracts } from '../api/contract';
import { PlusOutlined } from '@ant-design/icons';

const Contracts = props => {

var moment = require('moment');

const routes = [{ label: 'Главная', path: '/' }, 
                { label: 'Договора', path: '/contracts' }];

const columns = [
    {
        title: 'Договор',
        key: 'contractName',
        dataIndex: 'contractName',
        width: '65%',
    },
    {
        title: 'Тип договора',
        key: 'counterTypeSt',
        dataIndex: 'counterTypeSt',
        width: '15%',
    },
    {
        title: 'Дата заключения договора',
        width: '10%',
        render: row => {
            const datetime = moment(row.contractDate)
                .utc()
                .format('DD.MM.YYYY');
            return (
                <>
                    <p>{datetime}</p>
                </>
            );
        },
    },
    {
        title: 'Кто добавил',
        key: 'userLogin',
        dataIndex: 'userLogin',
        width: '10%',
    },
];

    const [loading, setLoading] = useState(false);
    
    const [contracts, setcontracts] = useState([]);

    const [contractName, setcontractName] = useState("");


    useEffect(() => {
        setLoading(true)
        API_getcontracts({ id: "", name: contractName})
        .then(data => setcontracts(data))
        .finally(setLoading(false));

    }, [contractName]);

    const [godMode, setgodMode] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('userType') == 1) {
            setgodMode(true)
        } 
    }, []);
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            
            <Form className="che-form">
                <PageTitle title="Договора" />
                <Form layout="vertycal">
                { godMode ?
                <div align="right">
                    <Button icon={<PlusOutlined />} style={{marginBottom : 20}} onClick={() => props.history.push('/addcontract')} type="primary">
                        Добавить договор
                    </Button>
                </div>
                : null}
                <Form.Item label="Название договора">
                    <Input.Search onSearch={setcontractName} placeholder="поиск по названию" />
                </Form.Item>
                </Form>
                <Spin spinning={loading}>
                    <Table
                        size="small"
                        columns={columns}
                        dataSource={contracts}
                        rowClassName="row-hover"
                        pagination={{
                            pageSize: 100,
                            position: 'both',
                        }}
                        onRow={contracts => {
                            return {
                                onClick: () => props.history.push(`/viewcontract/${contracts.id}`),
                            };
                        }}
                    />
                </Spin>
            </Form>
        </>
    );
};

export default withRouter(Contracts);
