import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, Spin, notification } from 'antd';
import { useState, useEffect } from 'react';
import { API_addobject } from './../api/objects'; 
import { SaveOutlined} from '@ant-design/icons';


const ObjectAdd = (props) => {

    const routes = [
        { label: 'Главная', path: '/' }, 
        { label: 'Объекты', path: `/objects` },
        { label: 'Добавление объекта', path: `/addobject` },
        ]

    const [loading, setLoading] = useState(false);    

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    nameObject: values.nameObject,
                    objectType: values.objectType,
                    objectAddress: values.objectAddress,
                    birthYear: values.birthYear,
                    objectArea: values.objectArea,
                    cadastrNum: values.cadastrNum,
                    nameObjectCadastr: values.nameObjectCadastr,
                };
                API_addobject(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Данные сохранены' });
                            props.history.push(`/objects`);
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    
    const [form] = Form.useForm();
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit} layout='vertical' form={form}>
                <PageTitle title="Добавление объекта"/>
                <Spin spinning={loading}>
                    <Form.Item label="Внутреннее имя объекта" style={{ marginBottom: 0 }} 
                    name="nameObject" rules={[{required: true, message: 'введите имя объекта'}]}>
                            <Input autoComplete="off" placeholder="введите имя объекта" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Кадастровое имя объекта" style={{ marginBottom: 0 }} 
                    name="nameObjectCadastr" rules={[{required: true, message: 'введите имя объекта'}]}>
                            <Input autoComplete="off" placeholder="введите имя объекта" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Тип объекта" style={{ marginBottom: 0 }} 
                    name="objectType" rules={[{required: false, message: 'введите тип'}]}>
                            <Input autoComplete="off" placeholder="введите тип" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Адрес" style={{ marginBottom: 0 }} 
                    name="objectAddress" rules={[{required: true, message: 'введите адрес'}]}>
                            <Input autoComplete="off" placeholder="введите адрес" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Кадастровый номер" style={{ marginBottom: 0 }} 
                    name="cadastrNum" rules={[{required: false, message: 'введите кадастровый номер'}]}>
                            <Input autoComplete="off" placeholder="введите кадастровый номер" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Год ввода" style={{ marginBottom: 0 }} 
                    name="birthYear" rules={[{required: false, message: 'введите год ввода'}]}>
                            <Input autoComplete="off" placeholder="введите год ввода" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Площадь кв.м." style={{ marginBottom: 0 }} 
                    name="objectArea" rules={[{required: false, message: 'введите площадь'}]}>
                            <Input autoComplete="off" placeholder="введите площадь" maxLength="250"/>
                    </Form.Item>
                    <Button style={{marginTop : 20}} icon={<SaveOutlined />} htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default ObjectAdd;