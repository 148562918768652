import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, Spin, notification, Select, DatePicker, Tooltip, Checkbox } from 'antd';
import { useState, useEffect } from 'react';
import { SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { API_addcontract } from './../api/contract'; 
import { API_getcountertypes } from './../api/counters';
import { API_getobjects } from './../api/objects';
import { API_getcontracts1c } from './../api/1c';

const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Договора', path: `/contracts` },
    { label: 'Добавить договор', path: `/addcontract` },
    ]

const ContractAdd = (props) => {

    var moment = require('moment');

    const [loading, setLoading] = useState(false);
    const [countertypes, setcountertypes] = useState([]);
    const [countertypeid, setcountertypeid] = useState(undefined);
    const [idObject, setidObject] = useState(undefined);
    const [objects, setobjects] = useState([]); 
    const [startDate, setstartDate] = useState(undefined);
    const [endDate, setendDate] = useState(undefined);
    const [delstartDate, setdelstartDate] = useState(undefined);
    const [delendDate, setdelendDate] = useState(undefined);
    const [contractDate, setcontractDate] = useState(undefined); 
    const [checkbox, setcheckbox] = useState(true);
    const [contracts1c, setcontracts1c] = useState([]); 
    const [contractId1C, setcontractId1C] = useState(undefined); 

    useEffect(() => {
        setLoading(true)
        API_getcontracts1c({ id: "", name: ""})
        .then(data => setcontracts1c(data))
        .finally(setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true)
        API_getobjects({ id: "", name: "", type: "", address: "", cadastr: ""})
        .then(data => setobjects(data))
        .finally(setLoading(false));
    }, []);


    function onChangestartDate(dateString) {
        if (dateString!=null) {
            setstartDate(dateString.format('YYYY-MM-DD'));
        }
    }
    function onChangeendDate(dateString) {
        if (dateString!=null) {
            setendDate(dateString.format('YYYY-MM-DD'));
        }
    }
    function onChangdelstartDate(dateString) {
        if (dateString!=null) {
            setdelstartDate(dateString.format('YYYY-MM-DD'));
        }
    }
    function onChangdelendDate(dateString) {
        if (dateString!=null) {
            setdelendDate(dateString.format('YYYY-MM-DD'));
        }
    } 
    function onChangecontractDate(dateString) {
        if (dateString!=null) {
            setcontractDate(dateString.format('YYYY-MM-DD'));
        }
    }

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    contractName: values.contractName,
                    contractType: 1,
                    contractNum: values.contractNum,
                    counterTypeId: countertypeid,
                    startDate: startDate,
                    endDate: endDate,
                    delstartDate: delstartDate,
                    delendDate: delendDate,
                    idObjects: idObject,
                    contractDate: contractDate,
                    contractId1C: contractId1C,
                };
                API_addcontract(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Данные сохранены' });
                            props.history.push('/contracts');
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true)
        API_getcountertypes()
        .then(data => setcountertypes(data))
        .finally(setLoading(false));
    }, []);

    function ChangeObj(value) {
        setidObject(`${value}`)
    }

    function ChangeCounterType(value) {
        setcountertypeid(`${value}`)
    }

    function ChangecontractId1C(value) {
        setcontractId1C(`${value}`)
        setLoading(true)
        API_getcontracts1c({ id: value, name: ""})
        .then(data => {
            form.setFieldsValue({
                contractNum: data[0].contractNum,
                contractDate: moment(data[0].contractDate),
                contractName: data[0].contractName
            })
            setcontractDate(moment(data[0].contractDate).format('YYYY-MM-DD'));
        })
        .finally(setLoading(false))
    }

    function onChangeCheckbox(e) {
        if (checkbox==true){
            form.setFieldsValue({
                endDate: moment('2099-01-01'),
                delendDate: moment('2099-01-01'),
            })
            setdelendDate('2099-01-01');
            setendDate('2099-01-01');
        } else {
            form.setFieldsValue({
                endDate: null,
                delendDate: null,
            })
        }
        setcheckbox(!checkbox)
    }
    
    const [form] = Form.useForm();

    const dateFormat = 'DD.MM.YYYY';

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit} layout='vertical' form={form}>
                <PageTitle title="Добавление договора"/>
                <Spin spinning={loading}>
                    <Form.Item label="Договор из 1С" style={{ marginBottom: 0 }} 
                    name="contractId1C" rules={[{required: true, message: 'Выберите договор'}]}>
                        <Select
                        onChange={ChangecontractId1C}
                        placeholder="Выберите договор"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {contracts1c && contracts1c.map(contracts1c => (
                            <Select.Option key={contracts1c.contractId} value={contracts1c.contractId}>
                                {contracts1c.contractName}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Номер договора" style={{ marginBottom: 0 }} 
                    name="contractNum" rules={[{required: true, message: 'введите номер договора'}]}>
                            <Input  autoComplete="off" placeholder="введите номер договора" maxLength="255"/>
                    </Form.Item>
                    <Form.Item label="Имя договора" style={{ marginBottom: 0 }} 
                    name="contractName" rules={[{required: true, message: 'введите имя договора'}]}>
                            <Input  autoComplete="off" placeholder="введите имя договора" maxLength="255"/>
                    </Form.Item>
                    <Form.Item label="Дата заключения договора" style={{ marginBottom: 0 }} name="contractDate" rules={[{required: true, message: 'Введите дату'}]}>
                                    <DatePicker  autoComplete="off" format={dateFormat} onChange={onChangecontractDate}/>
                    </Form.Item>
                    <Checkbox style={{ marginBottom: 10,marginTop: 10 }} onChange={onChangeCheckbox}>Договор заключен на неопределенный срок</Checkbox>
                    <Form.Item label="Тип договора" style={{ marginBottom: 0 }} 
                    name="counterType" rules={[{required: true, message: 'Выберите тип'}]}>
                        <Select
                        onChange={ChangeCounterType}
                        placeholder="Выберите тип"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        mode="multiple"
                        >
                        {countertypes && countertypes.map(types => (
                            <Select.Option key={types.counterTypeId} value={types.counterTypeId}>
                                {types.counterTypeName}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Дата вступления в силу" style={{ marginBottom: 0 }} name="startDate" rules={[{required: true, message: 'Введите дату'}]}>
                                    <DatePicker autoComplete="off" format={dateFormat} onChange={onChangestartDate}/>
                    </Form.Item>
                    <Form.Item label="Дата окончания действия" style={{ marginBottom: 0 }} name="endDate" rules={[{required: checkbox, message: 'Введите дату'}]}>
                                    <DatePicker  autoComplete="off" format={dateFormat} disabled={!checkbox} onChange={onChangeendDate}/>
                    </Form.Item>
                    <Form.Item  label="Дата начала поставки" style={{ marginBottom: 0 }} name="delstartDate" rules={[{required: true, message: 'Введите дату'}]}>
                                    <DatePicker  autoComplete="off" format={dateFormat} onChange={onChangdelstartDate}/>
                    </Form.Item>
                    <Form.Item  label="Дата конца поставки" style={{ marginBottom: 0 }} name="delendDate" rules={[{required: checkbox, message: 'Введите дату'}]}>
                                    <DatePicker  autoComplete="off" format={dateFormat} disabled={!checkbox} onChange={onChangdelendDate}/>
                    </Form.Item>
                    <Form.Item label="Объект"  name="objectSelect" rules={[{required: true, message: 'Выберите объект'}]}>
                            <Select
                                onChange={ChangeObj}
                                placeholder="Выберите объект"
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                mode="multiple"
                            >
                                {objects && objects.map(obj => (
                                    <Select.Option key={obj.id} value={obj.id}>
                                        <Tooltip title={obj.cadastrNum}><InfoCircleOutlined /></Tooltip> {obj.nameObject}({obj.objectAddress})
                                    </Select.Option>
                                ))}
                            </Select>
                    </Form.Item>
                    <Button style={{marginTop : 10}} icon={<SaveOutlined />} htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default ContractAdd;