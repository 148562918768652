import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import { Form, Spin, Button, Table, Tooltip, message, notification, Popconfirm, Space, Tabs } from 'antd';
import { useState, useEffect } from 'react';
import {  API_getcontracts } from '../api/contract';
import PageTitle from '../components/PageTitle';
import { EditOutlined, InboxOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { API_uploadfile, API_deletefile, API_getfile } from '../api/file'; 
import { API_url } from '../api/const';
import { API_deleteplan, API_getplans } from '../api/plans';
import { API_getcontracts1c, API_getpayments1c } from './../api/1c';

const ContractSingle = (props) => {

    var moment = require('moment');

    function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = " ") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      
          const negativeSign = amount < 0 ? "-" : "";
      
          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;
      
          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
        }
      };
    

    const [contracts, setcontracts] = useState([]);

    const [files, setfiles] = useState([]);

    const [loading, setLoading] = useState(false);

    const [update, setupdate] = useState(false);

    const [godMode, setgodMode] = useState(false);

    const [contracts1c, setcontracts1c] = useState([]); 

    const [payments1c, sepayments1c] = useState([]);

    const [sumpayments, setsumpayments] = useState(undefined);

    const [sumplan, setsumplan] = useState(undefined);

    const [dataplan, setdataplan] = useState(undefined);

    const [plans, setplans] = useState([]);

    useEffect(() => {
        setLoading(true)
        API_getplans({ idContract: props.match.params.id})
        .then(data => {
            setplans(data)
            try {
                setsumplan(data[0].planSumAll)
                setdataplan(data[0].planDataAll)
            }
            catch {
                setsumplan(0)
                setdataplan(0)
            }
        })
        .finally(setLoading(false));
    }, [update]);

    

    useEffect(() => {
        setLoading(true)
        API_getcontracts({ id: props.match.params.id, name: ""})
        .then(data => {
            setcontracts(data)
            setnamecontract(data[0].contractName)
            setLoading(true)
            API_getcontracts1c({ id: data[0].contractId1C, name: ""})
            .then(data => setcontracts1c(data))
            .finally(setLoading(false));
            setLoading(true)
            API_getpayments1c({ contractId: data[0].contractId1C})
            .then(data => {
                sepayments1c(data)
                setsumpayments(data[0].paymentSumAll)
            })
            .finally(setLoading(false));
        })
        .finally(setLoading(false));

    }, [update]);

    useEffect(() => {
        setLoading(true)
        API_getfile({ resId: props.match.params.id, fileType: 2})
        .then(data => {
            setfiles(data)
        })
        .finally(setLoading(false));

    }, [update]);

    useEffect(() => {
        if (localStorage.getItem('userType') == 1) {
            setgodMode(true)
        } 
    }, []);

    const [namecontract, setnamecontract] = useState(undefined);

    const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Договора', path: `/contracts` },
    { label: namecontract, path: `/viewcontract/${props.match.params.id}` },
    ]

    function MyDropzone() {
        const onDrop = useCallback((acceptedFiles) => {
          acceptedFiles.forEach((file) => {
                const reader = new FileReader()
                reader.readAsDataURL(file);
                reader.onabort = () => notification.error({ message: `Ошибка`, description: 'file reading was aborted' });
                reader.onerror = () => notification.error({ message: `Ошибка`, description: 'file reading has failed' });
                reader.onload = () => {

                const m5 = file.size / 1024 / 1024 < 5;

                if (!m5) {
                    message.error('Файл '+file.name+' должен быть меньше 5мб');
                } else {

                    let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');
                
                    const json = {
                    fileType: 2,
                    fileBody: fileSource,
                    fileName: file.name,
                    resId: Number(props.match.params.id),
                    };

                    API_uploadfile(json)
                    .then(function(response) {
                        notification.success({ message: file.name, description: 'Загружен' });
                        setupdate(!update)
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при загрузке' });
                        }
                        throw Error;
                    })

                }
            }
          })
        }, [])
        const {getRootProps, getInputProps} = useDropzone({onDrop})
      
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
           <p style={{ color: 'blue' }}><InboxOutlined style={{color : "blue"}}/>Нажмите сюда, чтобы загрузить договор</p>
          </div>
        )
      }
      

      const payments1ccolumns = [
        {
            title: 'Дата',
            key: 'paymentDate',
            dataIndex: 'paymentDate',
            width: '20%',
        },
        {
            title: 'Тип',
            key: 'paymentType',
            dataIndex: 'paymentType',
            width: '20%',
        },
        {
            title: 'Комментарий',
            key: 'paymentComment',
            dataIndex: 'paymentComment',
            width: '20%',
        },
        {
            title: 'Период',
            key: 'period',
            dataIndex: 'period',
            width: '20%',
        },
        {
            title: 'Сумма руб. с НДС',
            key: 'paymentSum',
            width: '20%',
            render: row => {
            return (
                <>
                    {<p>{formatMoney(row.paymentSum)}</p>}
                </>
            );
            },

        },

    ];  

    const { TabPane } = Tabs;

    const plancolumns = [
        {
            title: 'План по натуральному показателю',
            key: 'planData',
            dataIndex: 'planData',
            width: '10%',
        },
        {
            title: 'План руб. с НДС',
            key: 'planSumNds',
            width: '10%',
            render: row => {
                return (
                    <>
                        {<p>{formatMoney(row.planSumNds)}</p>}
                    </>
                );
            },
        },
        {
            title: 'Месяц',
            key: 'planPeriod',
            dataIndex: 'planPeriod',
            width: '10%',
        },
        {
            title: 'Объект',
            key: 'nameObjects',
            dataIndex: 'nameObjects',
            width: '50%',
        },
        {
            title: 'Тип прибора учета',
            key: 'typeCounter',
            dataIndex: 'typeCounter',
            width: '10%',
        },
        {
            title: 'Действия',
            render: row => (
                <Space size="middle">
                    <Tooltip title="Удалить план">
                        <Popconfirm title="Вы действительно хотите удалить？" placement="bottom" onConfirm={() => confirm(row.id)} okText="Да" cancelText="Нет">
                            <a><DeleteOutlined style={{ color: 'red' }}/></a>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
            width: '10%',
        },
    ];

    function confirm(id) {
        setLoading(true);
                const json = {
                    id: id,
                };
                API_deleteplan(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'План удален' });
                            setupdate(!update)
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
      }

      function deletefile(id) {
        setLoading(true);
                API_deletefile(id)
                    .then(function(response) {
                        if (response.IdFile==1) {
                            notification.success({ message: 'Файл удален' });
                            setupdate(!update)
                        }
                        if (response.IdFile==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
      }      

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form">
            <PageTitle title="Договор" />
                { godMode ?
                <div align="right">
                    <Tooltip title="Редактировать договор">
                        <Button icon={<EditOutlined />} onClick={() => props.history.push(`/editcontract/${props.match.params.id}`)} type="primary"> 
                        </Button>
                    </Tooltip>
                </div>
                : null}
                <Spin spinning={loading}>
                {contracts && contracts.map(contracts => (
                            <div>
                                <p>Номер договора: {contracts.contractNum}</p>

                                <p>Название договора: {contracts.contractName}.</p>

                                <p>Тип договора: {contracts.counterTypeSt}</p>

                                <p>Дата заключения договора: {moment(contracts.contractDate).format('DD.MM.YYYY')}</p>

                                <p>Объекты: {contracts.nameObjects}</p>

                                <p>Дата вступления в силу: {moment(contracts.startDate).format('DD.MM.YYYY')}</p>

                                <p>Дата окончания действия: {moment(contracts.endDate).format('DD.MM.YYYY')=='01.01.2099'?<p>Договор заключен на неопределенный срок</p>:moment(contracts.endDate).format('DD.MM.YYYY')}</p>

                                <p>Дата начала поставок: {moment(contracts.delstartDate).format('DD.MM.YYYY')}</p>

                                <p>Дата конца поставок: {moment(contracts.delendDate).format('DD.MM.YYYY')=='01.01.2099'?<p>Договор заключен на неопределенный срок</p>:moment(contracts.delendDate).format('DD.MM.YYYY')}</p>

                             </div>
                        ))}       
                {contracts1c && contracts1c.map(contracts1c => (
                            <div>
                                <p>Контрагент: {contracts1c.contragentName}(ИНН: {contracts1c.inn},КПП: {contracts1c.kpp})</p>

                                <p>Тип договора 1C: {contracts1c.contractType}.</p>

                                <p>Сумма по договору: {formatMoney(contracts1c.contractSum)} с НДС</p>
                             </div>
                        ))} 
                {files && files.map(file => (
                            <Form layout='inline'>
                                <p>
                                    Договор: <a style={{color : "blue"}} href={API_url+`/property/download/${file.fileIdentCode}`} target="_blank" download>{file.fileName}</a>
                                </p>
                                <p style={{ marginLeft: '20px' }}>
                                    <Tooltip title="Удалить файл">
                                        <Popconfirm title="Удалить файл？" placement="bottom" onConfirm={() => deletefile(file.fileIdentCode)} okText="Да" cancelText="Нет">
                                            <a><DeleteOutlined style={{ color: 'red' }}/></a>
                                        </Popconfirm>
                                    </Tooltip>
                                </p>
                            </Form>
                        ))}
                <Form style={{marginTop:"20px"}}>
                    <MyDropzone/>
                </Form>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="План" key="1">
                        <div align="right">
                            <Button icon={<PlusOutlined />} style={{marginBottom : 20}} onClick={() => props.history.push(`/addplane/${props.match.params.id}`)} type="primary">
                                Добавить план
                            </Button>
                        </div>
                        <PageTitle title="План по договору" />        
                            <div>
                                <p>Общая сумма по плану: {formatMoney(sumplan)} руб. с НДС</p>

                                <p>Общая сумма по показателю: {formatMoney(dataplan)}</p>
                             </div>
                            <Table
                                columns={plancolumns}
                                dataSource={plans}
                                rowClassName="row-hover"
                                pagination={false}
                                size="small"
                            />
                    </TabPane>
                    <TabPane tab="Платежи" key="2">
                        <PageTitle title={"Платежи по договору"} /> 
                            <div>
                                <p>Общая сумма платежей: {formatMoney(sumpayments)} руб. с НДС</p>

                             </div>       
                            <Table
                                columns={payments1ccolumns}
                                dataSource={payments1c}
                                rowClassName="row-hover"
                                pagination={false}
                                size="small"
                            />  
                    </TabPane>
                </Tabs> 
                </Spin>
            </Form>
        </>
    )
}

export default ContractSingle;