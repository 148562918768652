import axios from '../utils/axios';
import { notification } from 'antd';


export const API_getcontracts = (json) => {
    let url = `property/getcontracts?`;
    if (json) {
        if (json.id) url += `&Id=${json.id}`;
        if (json.name) url += `&name=${json.name}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_editcontract = json => {
    return axios({
        method: 'POST',
        url: `property/editcontract`,
        data: {
            contractName: json.contractName,
            contractType: json.contractType,
            id: json.id,
            contractType: json.contractType,
            contractNum: json.contractNum,
            counterTypeId: json.counterTypeId,
            startDate: json.startDate,
            endDate: json.endDate,
            delstartDate: json.delstartDate,
            delendDate: json.delendDate,
            idObjects: json.idObjects,
            contractDate: json.contractDate,
            contractId1C: json.contractId1C,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_addcontract = json => {
    return axios({
        method: 'POST',
        url: `property/addcontract`,
        data: {
            contractName: json.contractName,
            contractType: json.contractType,
            contractNum: json.contractNum,
            counterTypeId: json.counterTypeId,
            startDate: json.startDate,
            endDate: json.endDate,
            delstartDate: json.delstartDate,
            delendDate: json.delendDate,
            idObjects: json.idObjects,
            contractDate: json.contractDate,
            contractId1C: json.contractId1C,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};