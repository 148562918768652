import React from 'react'

const Footer = () => (
    <div style={{ paddingLeft: 20,height: 100, display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '##4C4D5A', flex: '0 0 auto', color: '#B2ABAB' }}>
    <p style={{ marginRight: 10 }}>ФГАОУ ВО СВФУ им. М.К. Аммосова © 2020</p>
    <p>
        <p style={{ marginRight: 20 }}>Техническая поддержка: <font color="#0099ff"><a href="mailto:property@s-vfu.ru">property@s-vfu.ru</a></font></p>
    </p>
</div>
)

export default Footer
