import axios from '../utils/axios';
import { notification } from 'antd';

export const API_uploadfile = json => {
    const url = `property/upload`;
    return axios({
        method:  'POST',
        url: url,
        data: {
            fileType: json.fileType,
            fileBody: json.fileBody,
            fileName: json.fileName,
            resId: json.resId,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_deletefile = fileID => {
    return axios({
        method: 'DELETE',
        url: `property/deletefile/${fileID}`,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getfile = (json) => {
    let url = `property/getfile?`;
    if (json) {
        if (json.resId) url += `&resId=${json.resId}`;
        if (json.fileType) url += `&fileType=${json.fileType}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};