import React, { PureComponent } from 'react'
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import { Form, Spin, DatePicker, Select, Tooltip as Tooltip2, Tabs } from 'antd';
import { useState, useEffect } from 'react';
import { API_getcalcdata } from '../api/report';
import PageTitle from '../components/PageTitle';
import { API_getcontracts } from '../api/contract';
import { API_getobjects } from './../api/objects';
import { InfoCircleOutlined } from '@ant-design/icons';
import { API_getcountertypes } from './../api/counters'; 
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Reports = (props) => {

    var moment = require('moment');

    function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = " ") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      
          const negativeSign = amount < 0 ? "-" : "";
      
          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;
      
          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
        }
      };

    const { TabPane } = Tabs;

    const startPeriod = '202001'//moment().startOf('year').format('YYYYMM');

    const  endPeriod = '202012'//moment().endOf('year').format('YYYYMM');

    const [records, setrecords] = useState([]);
    const [period, setperiod] = useState(startPeriod+'-'+endPeriod);
    const [loading, setLoading] = useState(false); 

    const { RangePicker } = DatePicker;

    const [countertypes, setcountertypes] = useState([]);
    const [contracts, setcontracts] = useState([]); 
    const [objects, setobjects] = useState([]);
    const [contractid, setcontractid] = useState(undefined); 
    const [objectid, setobjectid] = useState("");
    const [counterTypeId, setcounterTypeId] = useState("");
    const [update, setupdate] = useState(false);
    
    useEffect(() => {
        setLoading(true)
        API_getcountertypes()
        .then(data => setcountertypes(data))
        .finally(setLoading(false));
    }, []);

    /*useEffect(() => {
        setLoading(true)
        API_getobjects({ idContract: contractid, id: "", name: "", type: "", address: "", cadastr: ""})
        .then(data => setobjects(data))
        .finally(setLoading(false));
    }, [contractid]);*/


    useEffect(() => {
        setLoading(true)
        API_getcontracts({ id: "", name: ""})
        .then(data => {
            setcontracts(data)
        })
        .finally(setLoading(false));
    }, []);

    

    useEffect(() => {
        setLoading(true)
        API_getcalcdata({ period: period, objId: objectid, counterTypeId: counterTypeId, contractid: contractid})
        .then(data => setrecords(data))
        .finally(setLoading(false));
    }, [update]);

    const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Отчеты', path: `/reports` },
    ]

    class CustomizedLabel extends PureComponent {
        render() {
            const {
                x, y, stroke, value,
            } = this.props;
    
            return <text x={x+7.5} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle" >{formatMoney(value)}</text>;
        }
    }


    function onChangeplanPeriod(date,dateString) {
        setperiod(dateString[0]+'-'+dateString[1])
        setupdate(!update)
    }

    const monthFormat = 'YYYYMM';

    useEffect(() => {
        if (contractid>0) {
            setLoading(true)
            API_getcontracts({ id: contractid, name: ""})
            .then(data => {

                if (data!=undefined) {

                    setcounterTypeId(data[0].counterTypeId.split(',').map(Number))
                    setobjectid(data[0].idObjects.split(',').map(Number))

                    form.setFieldsValue({
                        objects: data[0].idObjects.split(',').map(Number),
                        counterType: data[0].counterTypeId.split(',').map(Number)
                    })

                    setupdate(!update)

                    setLoading(false)
                }
            });
        } else {
            setLoading(true)
            API_getobjects({ idContract: "", id: "", name: "", type: "", address: "", cadastr: ""})
            .then(data => setobjects(data))
            .finally(setLoading(false));
        }
    }, [contractid]);

    const [form] = Form.useForm();

    function contractHandleChange(value) {
            setcontractid(`${value}`)
    }

    function objectsHandleChange(value) {
        setobjectid(`${value}`)
        setupdate(!update)
    }

    function counterTypeHandleChange(value) {
        setcounterTypeId(`${value}`)
        setupdate(!update)
    }

    return (
        <>

            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" form={form}>
                <Spin spinning={loading}>
                <PageTitle title="Отчеты" />
                <Tabs defaultActiveKey="1">
                    <TabPane tab="График" key="1">
                        <Form.Item  label="Период (формат ГГГГММ)" style={{ marginBottom: 0 }} name="period" rules={[{required: true, message: 'Введите период'}]}>
                            <RangePicker style={{marginBottom : '20px'}} format={monthFormat} autoComplete="off" defaultValue={[moment(startPeriod,'YYYYMM'), moment(endPeriod,'YYYYMM')]} onChange={onChangeplanPeriod} picker="month"/>
                        </Form.Item>
                        <Form.Item label="Договор"  name="contract" rules={[{required: false, message: 'Выберите договор'}]}>
                            <Select
                                onChange={contractHandleChange}
                                placeholder="Выберите договор"
                                showSearch
                                optionFilterProp="children"
                                allowClear
                            >
                                {contracts && 
                                contracts.map(contracts => (
                                    <Select.Option key={contracts.id} value={contracts.id}>
                                        {contracts.contractName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Объект"  name="objects" rules={[{required: true, message: 'Выберите объект'}]}>
                            <Select
                                onChange={objectsHandleChange}
                                placeholder="Выберите объект"
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                mode="multiple"
                            >
                                {objects &&
                                objects.map(obj => (
                                    <Select.Option key={obj.id} value={obj.id}>
                                        <Tooltip2 title={obj.cadastrNum}><InfoCircleOutlined /></Tooltip2> {obj.nameObject}({obj.objectAddress})
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Тип прибора" style={{ marginBottom: 0 }} 
                        name="counterType" rules={[{required: true, message: 'Выберите тип'}]}>
                            <Select
                            style={{marginBottom : '20px'}}
                            onChange={counterTypeHandleChange}
                            placeholder="Выберите тип"
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            mode="multiple"
                            >
                            {countertypes && 
                            countertypes.map(types => (
                                <Select.Option key={types.counterTypeId} value={types.counterTypeId}>
                                    {types.counterTypeName}
                                </Select.Option>
                            ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                        <div id="node-to-convert">
                            <ResponsiveContainer width="100%" height={400}>
                            
                                <BarChart
                                    data={records}
                                    margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                                    >
                                        <CartesianGrid strokeDasharray="8 8" />
                                        <XAxis dataKey="period" padding={{ left: 30, right: 30 }} />
                                        <YAxis padding={{ top: 30}}/>
                                        <Tooltip />
                                        <Legend />

                                        <Bar  dataKey="Факт натуральные показатели" fill="#8884d8" label={<CustomizedLabel />}/>
                                        <Bar  dataKey="План натуральные показатели" fill="#82ca9d" label={<CustomizedLabel />}/>

                                </BarChart>  
                            </ResponsiveContainer>   
                            </div>
                        </Form.Item>    
                        <Form.Item>
                        <div id="node-to-convert">
                            <ResponsiveContainer width="100%" height={400}>
                            
                                <BarChart
                                    data={records}
                                    margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                                    >
                                        <CartesianGrid strokeDasharray="8 8" />
                                        <XAxis dataKey="period" padding={{ left: 30, right: 30 }} />
                                        <YAxis padding={{ top: 30}}/>
                                        <Tooltip />
                                        <Legend />

                                        <Bar  dataKey="Факт сумма руб. с НДС" fill="#8884d8" label={<CustomizedLabel />}/>
                                        <Bar  dataKey="План сумма руб. с НДС" fill="#82ca9d" label={<CustomizedLabel />}/>
                                        <Bar  dataKey="Платеж факт руб. c НДС" fill="#ffc658" label={<CustomizedLabel />}/>
                                </BarChart>  
                            </ResponsiveContainer>   
                            </div>
                        </Form.Item>   
                    </TabPane>
                </Tabs>   
                </Spin>
            </Form>
        </>
    )
}




export default Reports;