import React from 'react';
import { Menu, Layout } from 'antd';
import Header from './main/Header';
import { withRouter } from 'react-router-dom';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Objects from '../pages/Objects';
import ObjectSingle from '../pages/ObjectSingle';
import Counters from '../pages/Counters';
import CounterSingle from '../pages/CounterSingle';
import CounterAdd from '../pages/CounterAdd'; 
import ObjectEdit from '../pages/ObjectEdit';
import CounterEdit from '../pages/CounterEdit';
import Tarifs from '../pages/Tarifs';
import TarifSingle from '../pages/TarifSingle'; 
import TarifEdit from '../pages/TarifEdit';
import Footer from './main/Footer'; 
import TarifAdd from '../pages/TarifAdd';
import PlaneAdd from '../pages/PlaneAdd'; 
import Contracts from '../pages/Contracts'; 
import ContractEdit from '../pages/ContractEdit';
import ContractSingle from '../pages/ContractSingle'; 
import ContractAdd from '../pages/ContractAdd';
import Reports from '../pages/Reports';
import ObjectAdd from '../pages/ObjectAdd';

import { HomeOutlined, DashboardOutlined, InboxOutlined, FormOutlined, BarChartOutlined } from '@ant-design/icons';

const Main = props => {
    return (
        <div className="wrapper watermarked">
            <div className="content">
                <Header history={props.history} />
                <Layout>
                        <Menu
                            mode="horizontal"
                            activeKey={props.history.location.pathname}
                            selectedKeys={[props.history.location.pathname]}
                        >
                            <Menu.Item key="/objects">
                                <NavLink to="/objects">
                                    <HomeOutlined /> <span>Объекты</span>
                                </NavLink>
                                
                            </Menu.Item>
                            <Menu.Item key="/counters">
                            <NavLink to="/counters">
                                    <DashboardOutlined /> <span>Приборы учета</span>
                                </NavLink>
                            </Menu.Item>    
                            <Menu.Item key="/tarifs">
                            <NavLink to="/tarifs">
                                    <InboxOutlined /> <span>Тарифы</span>
                                </NavLink>
                            </Menu.Item>  
                            <Menu.Item key="/contracts">
                            <NavLink to="/contracts">
                                    <FormOutlined /> <span>Договора</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/reports">
                            <NavLink to="/reports">
                                    <BarChartOutlined /> <span>Отчеты</span>
                                </NavLink>
                            </Menu.Item>
                        </Menu>
                </Layout>   
                    <Layout style={{ padding: '0 24px 24px', background: '#FFF' }}>
                        <Route exact path="/" render={() => <Redirect to="/objects" />} />
                        <Switch>
                            <Route path="/objects" component={Objects} />
                            <Route path="/viewobject/:id" component={ObjectSingle} />
                            <Route path="/counters" component={Counters} />
                            <Route path="/viewcounter/:id" component={CounterSingle} />
                            <Route path="/addcounter" component={CounterAdd} />
                            <Route path="/editobject/:id" component={ObjectEdit} />
                            <Route path="/editcounter/:id" component={CounterEdit} />
                            <Route path="/tarifs" component={Tarifs} />
                            <Route path="/viewtarif/:id" component={TarifSingle} />
                            <Route path="/edittarif/:id" component={TarifEdit} />
                            <Route path="/addtarif" component={TarifAdd} />
                            <Route path="/addplane/:id" component={PlaneAdd} />
                            <Route path="/contracts" component={Contracts} />
                            <Route path="/editcontract/:id" component={ContractEdit} />
                            <Route path="/viewcontract/:id" component={ContractSingle} />
                            <Route path="/addcontract" component={ContractAdd} />
                            <Route path="/reports" component={Reports} />
                            <Route path="/addobject" component={ObjectAdd} />
                        </Switch>
                    </Layout>
                
            </div>
            <Footer />
        </div>
    );
};

export default withRouter(props => <Main {...props} />);
