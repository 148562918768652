import axios from '../utils/axios';
import { notification } from 'antd';


export const API_gettafifs = (json) => {
    let url = `property/gettarifs?`;
    if (json) {
        if (json.id) url += `&Id=${json.id}`;
        if (json.name) url += `&name=${json.name}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_edittarif = json => {
    return axios({
        method: 'POST',
        url: `property/edittarif`,
        data: {
                tarifName: json.tarifName,
                id: json.id,
                tarifType: json.tarifType,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_addtarif = json => {
    return axios({
        method: 'POST',
        url: `property/addtarif`,
        data: {
                tarifName: json.tarifName,
                tarifType: json.tarifType,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getprice = (json) => {
    let url = `property/getprice?`;
    if (json) {
        if (json.id) url += `&Id=${json.id}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_addprice = json => {
    return axios({
        method: 'POST',
        url: `property/addprice`,
        data: {
            period: json.period,
            price: json.price,
            priceType: json.priceType,
            idTarif: json.idTarif,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_deleteprice = json => {
    return axios({
        method: 'DELETE',
        url: `property/deleteprice`,
        data: {
            idPrice: json.idPrice,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};