import React from 'react'
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import { PageHeader, Form, Spin, Table, Button, Tooltip as Tooltip2, notification, Upload, message } from 'antd';
import { useState, useEffect } from 'react';
import {  API_getobjects } from '../api/objects';
import PageTitle from '../components/PageTitle';
import { API_getcounters } from '../api/counters';
import { PlusOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { API_uploadfile, API_deletefile } from '../api/file'; 
import { API_url } from '../api/const';

var moment = require('moment');

const ObjectSingle = (props) => {

    const columns = [
        {
            title: 'Имя счетчика',
            key: 'counterName',
            dataIndex: 'counterName',
            width: '40%',
        },
        {
            title: 'Модель',
            key: 'counterModel',
            dataIndex: 'counterModel',
            width: '20%',
        },
        {
            title: 'Марка',
            key: 'counterMark',
            dataIndex: 'counterMark',
            width: '20%',
        },
        {
            title: 'Тип',
            width: '20%',
            key: 'counterTypeName',
            dataIndex: 'counterTypeName',
        },
    ];

    const [counters, setcounters] = useState([]);

    const [objects, setobjects] = useState([]);

    const [loading, setLoading] = useState(false);

    const [godMode, setgodMode] = useState(false);

    const [imageUrl, setimageUrl] = useState(undefined);

    const [objimage, setobjimage] = useState(undefined);

    useEffect(() => {
        setLoading(true)
        API_getobjects({ id: props.match.params.id, name: "", type: "", address: "", cadastr: ""})
        .then(data => {
            setobjects(data)
            setnameObj(data[0].nameObject)
            setobjimage(data[0].imageObj)
            if (data[0].imageObj != "-") {
                setimageUrl(API_url+"/property/download/"+data[0].imageObj)
            }
        })
        .finally(setLoading(false));

    }, []);

    const [dis, setdis] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('userType') == 1) {
            setgodMode(true)
            setdis(false)
        } 
        localStorage.setItem('objId', props.match.params.id)
    }, []);

    useEffect(() => {
        setLoading(true)
        API_getcounters({ id: "", name: "", IdObject: props.match.params.id})
        .then(data => setcounters(data))
        .finally(setLoading(false));
    }, []);

    const [nameObj, setnameObj] = useState(undefined);

    const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Объекты', path: `/objects` },
    { label: nameObj, path: `/viewobject/${props.match.params.id}` },
    
    ]

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('Вы можете загрузить только JPG/PNG файлы');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Изображение должно быть меньше 5MB');
        }
        return isJpgOrPng && isLt2M;
      }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
        if (info.file.status === 'done') {
        var reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);
        reader.onload = () => {

            let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');

            const json = {
                fileType: 1,
                fileBody: fileSource,
                fileName: info.file.name,
                resId: Number(props.match.params.id),
            };

            API_uploadfile(json)
            .then(function(response) {

                setimageUrl(API_url+"/property/download/"+response.IdFile)

                if (objimage!=undefined) {
                    API_deletefile(objimage)
                }
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
        }
        setLoading(false);
        }
      };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };

    


    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form">
            <PageTitle title="Объект" />
                { godMode ?
                <div align="right">
                    <Tooltip2 title="Редактировать объект">
                        <Button icon={<EditOutlined />} onClick={() => props.history.push(`/editobject/${props.match.params.id}`)} type="primary"> 
                        </Button>
                    </Tooltip2>
                </div>
                : null}
                <Spin spinning={loading}>
                    <PageHeader
                    title={nameObj}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                        <p>
                            {objects && objects.map(objects => (
                                        <div>
                                            <p>Кадастровое имя объекта: {objects.nameObjectCadastr}</p>

                                            <p>Адрес объекта: {objects.objectAddress}</p>
                                            
                                            <p>Назначение объекта имущества: {objects.objectType}</p>

                                            <p>Кадастровый номер: {objects.cadastrNum}</p>

                                            <p>Год ввода в эксплуатацию: {objects.birthYear}</p>

                                            <p>Общая площадь, кв.м: {objects.objectArea}</p> 
                                        </div>
                                    ))} 
                        </p>  
                        <p>   
                            <Upload 
                                disabled={dis}
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={dummyRequest}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="Фото" style={{ width : '250px' }} /> : 
                                <div>
                                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div className="ant-upload-text">Фото</div>
                                </div>}
                            </Upload> 
                        </p> 
                        </div>
                </PageHeader>
                <div align="right">
                    <Button icon={<PlusOutlined />} style={{marginBottom : 20}}onClick={() => props.history.push('/addcounter')} type="primary">
                        Добавить прибор учета
                    </Button>
                </div>        
                <PageTitle title="Приборы учета" />
                    <Table
                        size="small"
                        columns={columns}
                        dataSource={counters}
                        rowClassName="row-hover"
                        pagination={false}
                        onRow={counters => {
                            return {
                                onClick: () => props.history.push(`/viewcounter/${counters.id}`),
                            };
                        }}
                    />       
                </Spin>
            </Form>
        </>
    )
}

export default ObjectSingle;