import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, Spin, notification, Select, DatePicker, Tooltip, Checkbox } from 'antd';
import { useState, useEffect } from 'react';
import { API_getcountertypes } from './../api/counters';
import { API_addplan } from './../api/plans';
import { API_getobjects } from './../api/objects'; 
import { SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { API_getcontracts } from '../api/contract';

const PlaneAdd = (props) => {


    const [contractName, setcontractName] = useState(undefined);

    const [loading, setLoading] = useState(false);

    const [idType, setidType] = useState(undefined); 

    const [idContract, setidContract] = useState(undefined);

    const [planPeriod, setplanPeriod] = useState(undefined); 

    const [idObject, setidObject] = useState(undefined);

    const [objects, setobjects] = useState([]);

    const [countertypes, setcountertypes] = useState([]);    

    const [contracts, setcontracts] = useState([]);

    const [Check, setCheck] = useState(false);

    useEffect(() => {
        setLoading(true)
        API_getcontracts({ id: props.match.params.id, name: ""})
        .then(data => {
            setcontractName(data[0].contractName)
        })
        .finally(setLoading(false));

    }, []);

    useEffect(() => {
        setLoading(true)
        API_getcontracts({ id: "", name: ""})
        .then(data => setcontracts(data))
        .finally(setLoading(false));

    }, []);

    useEffect(() => {
        setLoading(true)
        
        form.setFieldsValue({
            idContract: Number(props.match.params.id),
        })

        setidContract(props.match.params.id)

        API_getobjects({ idContract: props.match.params.id,id: "", name: "", type: "", address: "", cadastr: ""})
        .then(data => setobjects(data))
        .finally(setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true)
        API_getcountertypes()
        .then(data => setcountertypes(data))
        .finally(setLoading(false));
    }, []);
    

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    plan: Number(values.plan),
                    counterType: idType,
                    planPeriod: planPeriod,
                    objectId: String(idObject),
                    idContract: Number(idContract),
                    planSum: Number(values.planSum),
                    planSumNds: 0,
                };
                API_addplan(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Данные сохранены' });
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    function onChangeplanPeriod(dateString) {
        if (dateString!=null) {
            setplanPeriod(dateString.format('YYYYMM'));
        }
    }

    function handleChange(value) {
        setidObject(`${value}`)
      }
      
    
    const routes = [
        { label: 'Главная', path: '/' }, 
        { label: 'Договора', path: `/contracts` },
        { label: contractName, path: `/viewcontract/${props.match.params.id}` },
        { label: 'Добавление плана', path: `/addplane/${props.match.params.id}` },
        ]

    const [form] = Form.useForm();

    const onCheck = () => { 
        setCheck(!Check)
        setLoading(true)
        if (!Check) {
            API_getcontracts({ id: props.match.params.id, name: ""})
            .then(data => {
                form.setFieldsValue({
                    objectSelect: data[0].idObjects.split(',').map(Number),
                })
                setidObject(data[0].idObjects.split(',').map(Number))
            })
            .finally(setLoading(false));
        }
        else {
            form.setFieldsValue({
                objectSelect: undefined,
            })
            setidObject(undefined)
            setLoading(false)
        }
        
    }

    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" form={form} onFinish={handleSubmit} layout='vertical'>
                <PageTitle title="Добавление плана"/>
                <Spin spinning={loading}>
                    <Form.Item label="План по натуральному показателю" style={{ marginBottom: 0 }} 
                    name="plan" rules={[{required: true, message: 'введите план'}]}>
                            <Input autoComplete="off" type="number" placeholder="введите план" maxLength="255"/>
                    </Form.Item>
                    <Form.Item label="План руб. без НДС" style={{ marginBottom: 0 }} 
                    name="planSum" rules={[{required: true, message: 'введите план'}]}>
                            <Input autoComplete="off" type="number" placeholder="введите план" maxLength="255"/>
                    </Form.Item>
                    <Form.Item label="Тип прибора учета" style={{ marginBottom: 0 }} 
                    name="counterType" rules={[{required: true, message: 'выберите тип'}]}>
                        <Select
                        value={idType}
                        onChange={setidType}
                        placeholder="Выберите тип"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {countertypes &&
                        countertypes.map(types => (
                            <Select.Option key={types.counterTypeId} value={types.counterTypeId}>
                                {types.counterTypeName}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Договор" style={{ marginBottom: 0 }} 
                    name="idContract" rules={[{required: true, message: 'выберите договор'}]}>
                        <Select
                        value={idContract}
                        onChange={setidContract}
                        placeholder="Выберите договор"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {contracts &&
                        contracts.map(contract => (
                            <Select.Option key={contract.id} value={contract.id}>
                                {contract.contractName}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Выберите месяц" style={{ marginBottom: 0 }} name="planPeriod" rules={[{required: true, message: 'Введите месяц'}]}>
                                    <DatePicker autoComplete="off" onChange={onChangeplanPeriod} picker="month"/>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 10 }}>
                        <Checkbox onChange={onCheck} checked={Check} >Выбрать все объекты по договору</Checkbox>
                    </Form.Item>
                    <Form.Item label="Объект"  name="objectSelect" rules={[{required: true, message: 'Выберите объект'}]}>
                            <Select
                                onChange={handleChange}
                                placeholder="Выберите объект"
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                mode="multiple"
                            >
                                {objects && 
                                objects.map(obj => (
                                    <Select.Option key={obj.id} value={obj.id}>
                                        <Tooltip title={obj.cadastrNum}><InfoCircleOutlined /></Tooltip> {obj.nameObject}({obj.objectAddress})
                                    </Select.Option>
                                ))}
                            </Select>
                    </Form.Item>
                    <Button icon={<SaveOutlined />} htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default PlaneAdd;