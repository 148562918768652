import React from 'react';
import { withRouter } from 'react-router-dom';
import LoginForm from './../components/LoginForm';
import Footer from './main/Footer';

const Login = props => {
    return (
        <div
            style={{
                background: 'radial-gradient(circle,#fff,#f1f3ff,#dceaff,#c0e2ff,#9ddbff)',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div className="right-up"></div>
            <div className="right-down"></div>
            <div className="left-up"></div>
            <div className="left-down"></div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginBottom: 100,
                }}
            >
                <div className="login-logo-svfu"></div>
                <p style={{ textAlign: 'center', fontSize: '26px', fontWeight: 300 }}>Северо-Восточный федеральный университет им. М.К. Аммосова</p>
                <p style={{ textAlign: 'center', fontSize: '30px', marginBottom: '20px', fontWeight: 500 }}>Автоматизированная информационная система «Управление имуществом»</p>
                <div
                    style={{
                        width: '365px',
                        background: 'rgba(255, 255, 255, 0.7)',
                        padding: '16px 30px 40px 30px',
                        borderRadius: '2px',
                        boxShadow: ' 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                    }}
                >
                    <LoginForm history={props.history} />
                </div>
            </div>
            <Footer />
        </div>
        
    );
};


export default withRouter(props => <Login {...props} />);
