import React from 'react';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { Table, Spin, Form, Input, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_getobjects } from '../api/objects';
import { PlusOutlined } from '@ant-design/icons';

const Objects = props => {

    const [loading, setLoading] = useState(false);
    const [objects, setobjects] = useState([]);

    const [objectName, setobjectName] = useState("");
    const [objectType, setobjectType] = useState("");
    const [objectAddr, setobjectAddr] = useState("");
    const [objectCadastr, setobjectCadastr] = useState("");

    const routes = [{ label: 'Главная', path: '/' }, { label: 'Объекты', path: '/objects' }];

    const columns = [
        {
            title: '№',
            key: 'index',
            render: (text, record, index) => index+1,
            width: '5%',
        },
        {
            title: 'Кадастровое имя объекта',
            key: 'nameObjectCadastr',
            dataIndex: 'nameObjectCadastr',
            width: '25%',
        },
        {
            title: 'Внутреннее имя объекта',
            key: 'nameObject',
            dataIndex: 'nameObject',
            width: '25%',
        },
        {
            title: 'Назначение',
            key: 'objectType',
            dataIndex: 'objectType',
            width: '15%',
        },
        {
            title: 'Адрес',
            key: 'objectAddress',
            dataIndex: 'objectAddress',
            width: '25%',
        },
        {
            title: 'Кадастровый номер',
            key: 'cadastrNum',
            dataIndex: 'cadastrNum',
            width: '10%',
        },
    ];

    useEffect(() => {
        setLoading(true)
        API_getobjects({ id: "", name: objectName, type: objectType, address: objectAddr, cadastr: objectCadastr})
        .then(data => {
            setobjects(data)
            //console.log(data)
        })
        .finally(setLoading(false));

    }, [objectName,objectType,objectAddr,objectCadastr]);

    const [godMode, setgodMode] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('userType') == 1) {
            setgodMode(true)
        } 
    }, []);
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            
            <Form className="che-form">
                { godMode ?
                <div align="right">
                    <Button icon={<PlusOutlined />} style={{marginBottom : 20}} onClick={() => props.history.push('/addobject')} type="primary">
                        Добавить объект
                    </Button>
                </div>
                : null}
                <PageTitle title="Объекты" />
                <Form layout="vertycal">
                <Form.Item label="Название">
                    <Input.Search onSearch={setobjectName} placeholder="поиск по названию" />
                </Form.Item>
                <Form.Item label="Адрес">
                    <Input.Search onSearch={setobjectAddr} placeholder="поиск по адресу" />
                </Form.Item>
                </Form>
                <Spin spinning={loading}>
                    <Table
                        sticky
                        columns={columns}
                        dataSource={objects}
                        rowClassName="row-hover"
                        pagination={false}
                        size="small"
                        onRow={objects => {
                            return {
                                onClick: () => props.history.push(`/viewobject/${objects.id}`),
                            };
                        }}
                    />
                </Spin>
            </Form>
        </>
    );
};

export default withRouter(Objects);
