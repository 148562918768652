import axios from '../utils/axios';
import { notification } from 'antd';


export const API_getplans = (json) => {
    let url = `property/getplans?`;

    if (json) {
        if (json.idContract) url += `&idContract=${json.idContract}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};


export const API_deleteplan = json => {
    return axios({
        method: 'DELETE',
        url: `property/deleteplan`,
        data: {
            id: json.id,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_addplan = json => {
    return axios({
        method: 'POST',
        url: `property/addplan`,
        data: {
            planData: json.plan,
            counterType: json.counterType,
            planPeriod: json.planPeriod,
            objectId: json.objectId,
            planSum: json.planSum,
            planSumNds: json.planSumNds,
            idContract: json.idContract,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};