import React from 'react';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { Table, Spin, Form, Input, Select, Button, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_getobjects } from '../api/objects';
import { API_getcounters } from '../api/counters';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';

var moment = require('moment');

const routes = [{ label: 'Главная', path: '/' }, { label: 'Приборы учета', path: '/counters' }];

const columns = [
    {
        title: '№',
        key: 'index',
        render: (text, record, index) => index+1,
        width: '5%',
    },
    {
        title: 'Имя счетчика',
        key: 'counterName',
        dataIndex: 'counterName',
        width: '30%',
    },
    {
        title: 'Объект',
        key: 'nameObjects',
        dataIndex: 'nameObjects',
        width: '50%',
    },
    {
        title: 'Марка',
        key: 'counterMark',
        dataIndex: 'counterMark',
        width: '10%',
    },
    {
        title: 'Тип',
        width: '10%', 
        key: 'counterTypeName',
        dataIndex: 'counterTypeName',
    },
];

const Counters = props => {

    const [loading, setLoading] = useState(false);
    const [objects, setobjects] = useState([]);

    const [counters, setcounters] = useState([]);

    const [idObject, setidObject] = useState(undefined);

    const [objectName, setobjectName] = useState("");

    useEffect(() => {
        setLoading(true)
        localStorage.removeItem('objId')
        API_getobjects({ id: "", name: "", type: "", address: "", cadastr: ""})
        .then(data => setobjects(data))
        .finally(setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true)
        API_getcounters({ id: "", name: objectName, IdObject: idObject})
        .then(data => setcounters(data))
        .finally(setLoading(false));
    }, [objectName, idObject]);
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            
            <Form className="che-form">
                <div align="right">
                    <Button icon={<PlusOutlined />} style={{marginBottom : 20}} onClick={() => props.history.push('/addcounter')} type="primary">
                        Добавить прибор учета
                    </Button>
                </div> 
                <PageTitle title="Приборы учета" />
                <Form layout="vertycal">
                <Form.Item label="Название">
                    <Input.Search onSearch={setobjectName} placeholder="поиск по названию" />
                </Form.Item>
                <Form.Item label="Объект">
                            <Select
                                value={idObject}
                                onChange={setidObject}
                                placeholder="фильтр по объекту"
                                showSearch
                                optionFilterProp="children"
                                allowClear
                            >
                                {objects && objects.map(obj => (
                                    <Select.Option key={obj.id} value={obj.id}>
                                        <Tooltip title={obj.cadastrNum}><InfoCircleOutlined /></Tooltip> {obj.nameObject}({obj.objectAddress})
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                </Form>
                <Spin spinning={loading}>
                    <Table
                        sticky
                        columns={columns}
                        dataSource={counters}
                        rowClassName="row-hover"
                        pagination={false}
                        size="small"
                        onRow={counters => {
                            return {
                                onClick: () => props.history.push(`/viewcounter/${counters.id}`),
                            };
                        }}
                    />
                </Spin>
            </Form>
        </>
    );
};

export default withRouter(Counters);
