import axios from '../utils/axios';
import { notification } from 'antd';


export const API_getcalcdata = (json) => {
    let url = `property/getcalcdata?`;
    if (json) {
        if (json.period) url += `&period=${json.period}`;
        if (json.objId) url += `&objId=${json.objId}`; 
        if (json.counterTypeId) url += `&counterTypeId=${json.counterTypeId}`;
        if (json.contractid) if (json.contractid!='undefined') url += `&contractid=${json.contractid}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getdata = (json) => {
    let url = `property/getdata?`;
    if (json) {
        if (json.type) url += `&type=${json.type}`;
        if (json.id1) url += `&id1=${json.id1}`;
        if (json.id2) url += `&id2=${json.id2}`;
        if (json.id3) url += `&id3=${json.id3}`;
        if (json.id4) url += `&id4=${json.id4}`;
        if (json.id5) url += `&id5=${json.id5}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};