import axios from '../utils/axios';
import { notification } from 'antd';


export const API_getobjects = (json) => {
    let url = `property/getobjects?`;
    if (json) {
        if (json.id) url += `&Id=${json.id}`;
        if (json.name) url += `&name=${json.name}`;
        if (json.type) url += `&type=${json.type}`;
        if (json.address) url += `&address=${json.address}`;
        if (json.cadastr) url += `&cadastr=${json.cadastr}`;
        if (json.idContract) url += `&idContract=${json.idContract}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_editobject = json => {
    return axios({
        method: 'POST',
        url: `property/editobject`,
        data: {
                    nameObject: json.nameObject,
                    objectType: json.objectType,
                    objectAddress: json.objectAddress,
                    birthYear: json.birthYear,
                    objectArea: json.objectArea,
                    cadastrNum: json.cadastrNum,
                    objectId: json.objectId,
                    nameObjectCadastr: json.nameObjectCadastr,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_addobject = json => {
    return axios({
        method: 'POST',
        url: `property/addobject`,
        data: {
                    nameObject: json.nameObject,
                    objectType: json.objectType,
                    objectAddress: json.objectAddress,
                    birthYear: json.birthYear,
                    objectArea: json.objectArea,
                    cadastrNum: json.cadastrNum,
                    nameObjectCadastr: json.nameObjectCadastr,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};