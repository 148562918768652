import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, Spin, notification, Select } from 'antd';
import { useState, useEffect } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { API_addtarif } from './../api/tarif';
import { API_getcountertypes } from './../api/counters';

const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Тарифы', path: `/tarifs` },
    { label: 'Добавить тариф', path: `/addtarif` },
    ]

const TarifAdd = (props) => {

    const [loading, setLoading] = useState(false);

    const [countertypes, setcountertypes] = useState([]);

    const [countertypeid, setcountertypeid] = useState(undefined);

    
    useEffect(() => {
        setLoading(true)
        API_getcountertypes()
        .then(data => setcountertypes(data))
        .finally(setLoading(false));
    }, []);

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    tarifName: values.tarifName,
                    tarifType: countertypeid,
                };
                API_addtarif(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Данные сохранены' });
                            props.history.push('/tarifs');
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit} layout='vertical'>
                <PageTitle title="Добавление тарифа"/>
                <Spin spinning={loading}>
                    <Form.Item label="Имя тарифа" style={{ marginBottom: 0 }} 
                    name="tarifName" rules={[{required: true, message: 'введите имя тарифа'}]}>
                            <Input autoComplete="off" placeholder="введите имя тарифа" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Тип тарифа" style={{ marginBottom: 0 }} 
                    name="tarifType" rules={[{required: true, message: 'Выберите тип'}]}>
                        <Select
                        value={countertypeid}
                        onChange={setcountertypeid}
                        placeholder="Выберите тип"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {countertypes && 
                        countertypes.map(types => (
                            <Select.Option key={types.counterTypeId} value={types.counterTypeId}>
                                {types.counterTypeName}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Button style={{marginTop : 10}} icon={<SaveOutlined />} htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default TarifAdd;