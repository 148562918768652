import axios from '../utils/axios';
import { notification } from 'antd';


export const API_getcounters = (json) => {
    let url = `property/getcounters?`;
    if (json) {
        if (json.id) url += `&Id=${json.id}`;
        if (json.name) url += `&name=${json.name}`;
        if (json.IdObject) url += `&IdObject=${json.IdObject}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_addcounter = json => {
    return axios({
        method: 'POST',
        url: `property/addcounter`,
        data: {
            counterName: json.counterName,
            counterType: json.counterType,
            counterSerialNum: json.counterSerialNum,
            counterModel: json.counterModel,
            counterMark: json.counterMark,
            dateCounter: json.dateCounter,
            dateSet: json.dateSet,
            startRecord1: json.startRecord1,
            startRecord2: json.startRecord2,
            dateVerification: json.dateVerification,
            dateVerificationPlane: json.dateVerificationPlane,
            objectId: json.objectId,
            tarifId: json.tarifId,
            counterKoef: json.counterKoef,
            recordType: json.recordType,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_addcounterrecord = json => {
    return axios({
        method: 'POST',
        url: `property/addrecord`,
        data: {
            record: json.record,
            dateRecord: json.dateRecord,
            counterId: json.counterId,
            priceType: json.priceType,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getrecords = (json) => {
    let url = `property/getrecords?`;
    if (json) {
        if (json.id) url += `&Id=${json.id}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getcountertypes = () => {
    let url = `property/getcountertypes`;
    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_editcounter = json => {
    return axios({
        method: 'POST',
        url: `property/editcounter`,
        data: {
            id: json.counterId,
            counterName: json.counterName,
            counterType: json.counterType,
            counterSerialNum: json.counterSerialNum,
            counterModel: json.counterModel,
            counterMark: json.counterMark,
            dateCounter: json.dateCounter,
            dateSet: json.dateSet,
            startRecord1: json.startRecord1,
            startRecord2: json.startRecord2,
            dateVerification: json.dateVerification,
            dateVerificationPlane: json.dateVerificationPlane,
            idObjects: json.objectId,
            tarifId: json.tarifId,
            counterKoef: json.counterKoef,
            recordType: json.recordType,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_deleterecord = json => {
    return axios({
        method: 'DELETE',
        url: `property/deleterecord`,
        data: {
            id: json.recordId,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};