import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, Spin, notification, Select, DatePicker } from 'antd';
import { useState, useEffect } from 'react';
import { API_editobject } from './../api/objects'; 
import { API_getobjects } from './../api/objects';
import { SaveOutlined} from '@ant-design/icons';


const ObjectEdit = (props) => {

    const [nameObj, setnameObj] = useState(undefined);

    const routes = [
        { label: 'Главная', path: '/' }, 
        { label: 'Объекты', path: `/objects` },
        { label: nameObj, path: `/viewobject/${props.match.params.id}` },
        { label: 'Редактирование объекта', path: `/editobject` },
        ]

    const [loading, setLoading] = useState(false);    

    useEffect(() => {
        setLoading(true)
        API_getobjects({ id: props.match.params.id, name: "", type: "", address: "", cadastr: ""})
        .then(response => {
            form.setFieldsValue({
                nameObject: response[0].nameObject,
                objectType: response[0].objectType,
                objectAddress: response[0].objectAddress,
                birthYear: response[0].birthYear,
                objectArea: response[0].objectArea,
                cadastrNum: response[0].cadastrNum,
                nameObjectCadastr: response[0].nameObjectCadastr,
            })
            setnameObj(response[0].nameObject)
            }
        )

        .finally(setLoading(false));
    }, []);

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    nameObject: values.nameObject,
                    objectType: values.objectType,
                    objectAddress: values.objectAddress,
                    birthYear: values.birthYear,
                    objectArea: values.objectArea,
                    cadastrNum: values.cadastrNum,
                    objectId: props.match.params.id,
                    nameObjectCadastr: values.nameObjectCadastr,
                };
                API_editobject(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Данные сохранены' });
                            props.history.push(`/viewobject/${props.match.params.id}`);
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    
    const [form] = Form.useForm();
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit} layout='vertical' form={form}>
                <PageTitle title="Редактирование объекта"/>
                <Spin spinning={loading}>
                    <Form.Item label="Внутреннее имя объекта" style={{ marginBottom: 0 }} 
                    name="nameObject" rules={[{required: true, message: 'введите имя объекта'}]}>
                            <Input autoComplete="off" placeholder="введите имя объекта" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Кадастровое имя объекта" style={{ marginBottom: 0 }} 
                    name="nameObjectCadastr" rules={[{required: true, message: 'введите имя объекта'}]}>
                            <Input autoComplete="off" placeholder="введите имя объекта" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Тип объекта" style={{ marginBottom: 0 }} 
                    name="objectType" rules={[{required: false, message: 'введите тип'}]}>
                            <Input autoComplete="off" placeholder="введите тип" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Адрес" style={{ marginBottom: 0 }} 
                    name="objectAddress" rules={[{required: true, message: 'введите адрес'}]}>
                            <Input autoComplete="off" placeholder="введите адрес" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Кадастровый номер" style={{ marginBottom: 0 }} 
                    name="cadastrNum" rules={[{required: false, message: 'введите кадастровый номер'}]}>
                            <Input autoComplete="off" placeholder="введите кадастровый номер" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Год ввода" style={{ marginBottom: 0 }} 
                    name="birthYear" rules={[{required: false, message: 'введите год ввода'}]}>
                            <Input autoComplete="off" placeholder="введите год ввода" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Площадь кв.м." style={{ marginBottom: 0 }} 
                    name="objectArea" rules={[{required: false, message: 'введите площадь'}]}>
                            <Input autoComplete="off" placeholder="введите площадь" maxLength="250"/>
                    </Form.Item>
                    <Button style={{marginTop : 20}} icon={<SaveOutlined />} htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default ObjectEdit;