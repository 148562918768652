import axios from '../utils/axios';
import { notification } from 'antd';


export const API_getcontracts1c = (json) => {
    let url = `property/getcontracts1c?`;
    if (json) {
        if (json.id) url += `&id=${json.id}`;
        if (json.name) url += `&name=${json.name}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getpayments1c = (json) => {
    let url = `property/getpayments1c?`;
    if (json) {
        if (json.contractId) url += `&contractId=${json.contractId}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};