import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { Link, NavLink} from 'react-router-dom'
import { UserOutlined, BankOutlined, PoweroffOutlined, CrownOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react'; 
import logo from './../../images/logo-aisui.png'; 

const Header = props => {
    const logout = () => {
        localStorage.removeItem('accessToken')
        props.history.push('/login')
    }

    useEffect(() => {
        if (localStorage.getItem('userType') == 1) {
            setgodMode(true)
        } 
    }, []);

    const [godMode, setgodMode] = useState(false);
    
    return (
        <div
            style={{
                height: 100,
                background: '#FFF',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 20,
                color: '#4C4D5A',
            }}
        >
            <p style={{ fontSize: 20 }}>
                <NavLink to="/">
                    <img src={logo} alt="Logo" />
                </NavLink>
            </p>

            
            <p style={{ fontSize: 16, marginRight: 20 }}>
                { godMode ?
                <CrownOutlined style={{ color: "gold", marginRight: 10 }}/>
                :<UserOutlined style={{ marginRight: 10 }}/> 
                }
                    {props.login}{` `}
                <Button icon={<PoweroffOutlined />} shape="circle" size="small" onClick={logout} />
            </p>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        login: state.user.login,
    }
}

export default connect(mapStateToProps)(Header)
