import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, Spin, notification, Select, DatePicker, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { API_editcounter } from './../api/counters'; 
import { API_getobjects } from './../api/objects';
import { SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { API_getcounters, API_getcountertypes } from './../api/counters'; 
import { API_gettafifs } from '../api/tarif';

var moment = require('moment');

const CounterEdit = (props) => {

    const [idType, setidType] = useState(undefined);

    const [DateCounter, setDateCounter] = useState(undefined); 

    const [DateSet, setDateSet] = useState(undefined);

    const [DateVer, setDateVer] = useState(undefined);

    const [DateVerPlane, setDateVerPlane] = useState(undefined);

    const [idObject, setidObjects] = useState(undefined);

    const [objects, setobjects] = useState([]);

    const [countertypes, setcountertypes] = useState([]);

    const [tarifs, settarifs] = useState([]);

    const [idTarif, setidTarif] = useState(undefined);

    const [recordType, setrecordType] = useState(undefined);

    const [nameCounter, setnameCounter] = useState(undefined);

    const routes = [
        { label: 'Главная', path: '/' }, 
        { label: 'Приборы учета', path: `/counters` },
        { label: nameCounter, path: `/viewcounter/${props.match.params.id}` },
        { label: 'Редактирование прибора учета', path: `/editcounter` },
        ]

    const [loading, setLoading] = useState(false);    

    useEffect(() => {
        setLoading(true)
        
        API_getcounters({ id:props.match.params.id, name: "", IdObject: ""})
        .then(response => {
            form.setFieldsValue({
                counterName: response[0].counterName,
                counterSerialNum: response[0].counterSerialNum,
                counterModel: response[0].counterModel,
                counterMark: response[0].counterMark,
                counterType: response[0].counterType,
                dateCounter: moment(response[0].dateCounter),
                dateSet: moment(response[0].dateSet),
                dateVerification: moment(response[0].dateVerification),
                dateVerificationPlane: moment(response[0].dateVerificationPlane),
                objectSelect: response[0].idObjects.split(',').map(Number),
                tarif: response[0].tarifId,
                startRecord1: response[0].startRecord1,
                startRecord2: response[0].startRecord2,
                counterKoef: response[0].counterKoef,
                recordType: response[0].recordType,
            })
            if (moment(response[0].dateCounter).format('DD.MM.YYYY')!='01.01.1900') {
                setDateCounter(moment(response[0].dateCounter).format('YYYY-MM-DD'))
            } else {
                form.setFieldsValue({
                    dateCounter: undefined,
                })
            }
            if (moment(response[0].dateSet).format('DD.MM.YYYY')!='01.01.1900') {
                setDateSet( moment(response[0].dateSet).format('YYYY-MM-DD'))
            } else {
                form.setFieldsValue({
                    dateSet: undefined,
                })
            }
            
            setDateVer(moment(response[0].dateVerification).format('YYYY-MM-DD'));
            setDateVerPlane(moment(response[0].dateVerificationPlane).format('YYYY-MM-DD'));
            setidType(response[0].counterType)
            setidObjects(response[0].idObjects.split(',').map(Number))
            setidTarif(response[0].tarifId)
            setnameCounter(response[0].counterName)
            setrecordType(response[0].recordType)
        }
            
            )
        .finally(setLoading(false));
    }, []);

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    counterId: Number(props.match.params.id),
                    counterName: values.counterName,
                    counterType: idType,
                    counterSerialNum: values.counterSerialNum,
                    counterModel: values.counterModel,
                    counterMark: values.counterMark,
                    dateCounter: DateCounter,
                    dateSet: DateSet,
                    startRecord1:  Number(values.startRecord1),
                    startRecord2:  Number(values.startRecord2),
                    dateVerification: DateVer,
                    dateVerificationPlane: DateVerPlane,
                    objectId: String(idObject),
                    tarifId: idTarif,
                    counterKoef: Number(values.counterKoef),
                    recordType: Number(recordType),
                };
                API_editcounter(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Данные сохранены' });
                            props.history.push(`/viewcounter/${props.match.params.id}`);
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };
    
    useEffect(() => {
        setLoading(true)
        API_gettafifs({ id: "", name: ""})
        .then(data => settarifs(data))
        .finally(setLoading(false));

    }, []);

    useEffect(() => {
        setLoading(true)
        API_getobjects({ id: "", name: "", type: "", address: "", cadastr: ""})
        .then(data => setobjects(data))
        .finally(setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true)
        API_getcountertypes()
        .then(data => setcountertypes(data))
        .finally(setLoading(false));
    }, []);

    
    const [form] = Form.useForm();

    function onChangeDateCounter(dateString) {
        if (dateString!=null) {
            setDateCounter(dateString.format('YYYY-MM-DD'));
        } else {
            setDateCounter('1900-01-01')
        }
    }

    function onChangeDateSet(dateString) {
        if (dateString!=null) {
            setDateSet(dateString.format('YYYY-MM-DD'));
        } else {
            setDateSet('1900-01-01')
        }
    }
    function onChangeDateVer(dateString) {
        if (dateString!=null) {
            setDateVer(dateString.format('YYYY-MM-DD'));
        }
    }
    function onChangeDateVerPlane(dateString) {
        if (dateString!=null) {
            setDateVerPlane(dateString.format('YYYY-MM-DD'));
        }
    }

    function handleChange(value) {
        setidObjects(`${value}`)
      }

    const dateFormat = 'DD.MM.YYYY';
    
    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit} layout='vertical' form={form}>
                <PageTitle title="Редактирование прибора учета"/>
                <Spin spinning={loading}>
                <Form.Item label="Наименование" style={{ marginBottom: 0 }} 
                    name="counterName" rules={[{required: true, message: 'Введите наименование'}]}>
                            <Input autoComplete="off" placeholder="введите наименование" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Тип прибора" style={{ marginBottom: 0 }} 
                    name="counterType" rules={[{required: true, message: 'Выберите тип'}]}>
                        <Select
                        value={idType}
                        onChange={setidType}
                        placeholder="Выберите тип"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {countertypes && countertypes.map(types => (
                            <Select.Option key={types.counterTypeId} value={types.counterTypeId}>
                                {types.counterTypeName}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Тариф" style={{ marginBottom: 0 }} 
                    name="tarif" rules={[{required: true, message: 'Выберите тариф'}]}>
                        <Select
                        value={idTarif}
                        onChange={setidTarif}
                        placeholder="Выберите тариф"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {tarifs && tarifs.map(tarif => (
                            <Select.Option key={tarif.id} value={tarif.id}>
                                {tarif.tarifName}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Начальные показания прибора учета (Обычный)" style={{ marginBottom: 0 }} 
                    name="startRecord1" rules={[{required: true, message: 'введите показания'}]}>
                            <Input autoComplete="off" type="number" placeholder="введите показания" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Начальные показания прибора учета (Ночной)" style={{ marginBottom: 0 }} 
                    name="startRecord2" rules={[{required: true, message: 'введите показания'}]}>
                            <Input autoComplete="off" type="number" placeholder="введите показания" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Коэфициент" style={{ marginBottom: 0 }} 
                    name="counterKoef" rules={[{required: true, message: 'введите коэфициент'}]}>
                            <Input autoComplete="off" type="number" placeholder="введите коэфициент" maxLength="250"/>
                    </Form.Item> 
                    <Form.Item label="Серийный номер" style={{ marginBottom: 0 }} 
                    name="counterSerialNum" rules={[{required: true, message: 'Введите серийный номер'}]}>
                            <Input autoComplete="off" placeholder="Введите серийный номер" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Модель" style={{ marginBottom: 0 }} 
                    name="counterModel" rules={[{required: true, message: 'Введите модель'}]}>
                            <Input autoComplete="off" placeholder="Введите модель" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Марка" style={{ marginBottom: 0 }} 
                    name="counterMark" rules={[{required: true, message: 'Введите марку'}]}>
                            <Input autoComplete="off" placeholder="Введите марку" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Дата выпуска" style={{ marginBottom: 0 }} name="dateCounter" rules={[{required: false, message: 'Введите дату выпуска'}]}>
                                    <DatePicker autoComplete="off" format={dateFormat} onChange={onChangeDateCounter}/>
                    </Form.Item>
                    <Form.Item label="Дата установки" style={{ marginBottom: 0 }} name="dateSet" rules={[{required: false, message: 'Введите дату установки'}]}>
                                    <DatePicker autoComplete="off" format={dateFormat} onChange={onChangeDateSet}/>
                    </Form.Item>
                    <Form.Item label="Дата поверки" style={{ marginBottom: 0 }} name="dateVerification" rules={[{required: true, message: 'Введите дату поверки'}]}>
                                    <DatePicker autoComplete="off" format={dateFormat} onChange={onChangeDateVer}/>
                    </Form.Item>
                    <Form.Item label="Дата поверки плановая" style={{ marginBottom: 0 }} name="dateVerificationPlane" rules={[{required: true, message: 'Введите дату плановой поверки'}]}>
                                    <DatePicker autoComplete="off" format={dateFormat} onChange={onChangeDateVerPlane}/>
                    </Form.Item>
                    <Form.Item label="Тип ввода данных" style={{ marginBottom: 0 }} 
                    name="recordType" rules={[{required: true, message: 'Выберите тип'}]}>
                        <Select
                        value={recordType}
                        onChange={setrecordType}
                        placeholder="Выберите тип"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                            <Select.Option key={1} value={1}>
                                Показание счетчика
                            </Select.Option>
                            <Select.Option key={2} value={2}>
                                Разница показаний
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Объект"  name="objectSelect" rules={[{required: true, message: 'Выберите объект'}]}>
                            <Select
                                onChange={handleChange}
                                placeholder="Выберите объект"
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                mode="multiple"
                            >
                                {objects && objects.map(obj => (
                                    <Select.Option key={obj.id} value={obj.id}>
                                        <Tooltip title={obj.cadastrNum}><InfoCircleOutlined /></Tooltip> {obj.nameObject}({obj.objectAddress})
                                    </Select.Option>
                                ))}
                            </Select>
                    </Form.Item>
                    <Button icon={<SaveOutlined />} htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default CounterEdit;