import  React from 'react'
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { Form, Button, Input, Spin, notification, Select } from 'antd';
import { useState, useEffect } from 'react';
import { API_edittarif } from './../api/tarif'; 
import { API_gettafifs } from './../api/tarif';
import { SaveOutlined} from '@ant-design/icons';
import { API_getcountertypes } from './../api/counters';

const TarifEdit = (props) => {

    const [nameTarif, setnameTarif] = useState(undefined);

    const [countertypes, setcountertypes] = useState([]);

    const [countertypeid, setcountertypeid] = useState(undefined);

    
    useEffect(() => {
        setLoading(true)
        API_getcountertypes()
        .then(data => setcountertypes(data))
        .finally(setLoading(false));
    }, []);

    const routes = [
        { label: 'Главная', path: '/' }, 
        { label: 'Тарифы', path: `/tarifs` },
        { label: nameTarif, path: `/viewtarif/${props.match.params.id}` },
        { label: 'Редактирование тарифа', path: `/edittarif` },
        ]

    var moment = require('moment');

    const [loading, setLoading] = useState(false);    

    useEffect(() => {
        setLoading(true)
        API_gettafifs({ id: props.match.params.id, name: ""})
        .then(response => {
            form.setFieldsValue({
                tarifName: response[0].tarifName,
                tarifType: response[0].tarifType,
            })
            setnameTarif(response[0].tarifName)
            setcountertypeid(response[0].tarifType)
            }
            )
        .finally(setLoading(false));
    }, []);

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    tarifName: values.tarifName,
                    id: Number(props.match.params.id),
                    tarifType: countertypeid,
                };
                API_edittarif(json)
                    .then(function(response) {
                        if (response.id==1) {
                            notification.success({ message: 'Данные сохранены' });
                            props.history.push(`/viewtarif/${props.match.params.id}`);
                        }
                        if (response.id==0) {
                            notification.error({ message: `Произошла ошибка, пожалуйста попробуйте позже` });
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };
    
    const [form] = Form.useForm();

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Form className="che-form" onFinish={handleSubmit} layout='vertical' form={form}>
                <PageTitle title="Редактирование тарифа"/>
                <Spin spinning={loading}>
                    <Form.Item label="Имя тарифа" style={{ marginBottom: 0 }} 
                    name="tarifName" rules={[{required: true, message: 'введите имя тарифа'}]}>
                            <Input autoComplete="off" placeholder="введите имя тарифа" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Тип тарифа" style={{ marginBottom: 0 }} 
                    name="tarifType" rules={[{required: true, message: 'Выберите тип'}]}>
                        <Select
                        value={countertypeid}
                        onChange={setcountertypeid}
                        placeholder="Выберите тип"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        >
                        {countertypes && 
                        countertypes.map(types => (
                            <Select.Option key={types.counterTypeId} value={types.counterTypeId}>
                                {types.counterTypeName}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Button style={{marginTop : 10}} icon={<SaveOutlined />} htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default TarifEdit;